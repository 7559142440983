import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function LandingPageNavbar() {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const [activeLink, setActiveLink] = useState(location.pathname);

    const handleClick = (path) => {
        setActiveLink(path);
    };

    return <>
        <nav className="container-fluid flex flex-wrap navbar navbar-expand-lg bg-side-color pt-4">
            <div className="container-fluid ">
            <img src="/Images/nav-logo.png" className="land-logo ms-5" alt="Logo" />
            <button
                className="navbar-toggler bg-white"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span className="navbar-toggler-icon"></span>
            </button>
            <div
                className="collapse navbar-collapse "
                id="navbarSupportedContent"
            >
                <ul className="navbar-nav mb-2 mb-lg-0 land-nav land-nav-res">
                    <li className="nav-item">
                        <Link
                            className={`nav-link ${activeLink === '/' ? 'text-warning border-bottom border-warning' : 'text-white'}`}
                            aria-current="page"
                            to="/"
                            onClick={() => handleClick('/')}
                        >
                            {t("home")}
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link
                            className={`nav-link ${activeLink === '/landingpage-teacher' ? 'text-warning border-bottom border-warning' : 'text-white'}`}
                            to="/landingpage-teacher"
                            onClick={() => handleClick('/landingpage-teacher')}
                        >
                            Our teachers
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link
                            className={`nav-link ${activeLink === '/landingpage-course' ? 'text-warning border-bottom border-warning ' : 'text-white'}`}
                            to="/landingpage-course"
                            onClick={() => handleClick('/landingpage-course')}
                        >
                            {t("courses")}
                        </Link>
                    </li>
                </ul>
                <ul className="navbar-nav mb-2 mb-lg-0 ms-auto me-5">
                
                <li className="nav-item  btn btn-warning">
                    <Link className="nav-link" to="/student/home">
                      {t("getStarted")}
                    </Link>
                </li>
                </ul>
            </div>
            </div>
        </nav>
    </>
}
