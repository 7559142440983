import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import LandingPageTeacherItem from "./LandingPageTeacherItem";
import { Circles } from "react-loader-spinner";
import { useTranslation } from "react-i18next";

export default function LandingPageTeacher() {
    const [teachers, setTeachers] = useState([]);
    const [isLoading, setIsLoading] = useState(true); 
    const { t } = useTranslation();

    useEffect(() => {
        getTeachers();
    }, []);
    const getTeachers = async () => {
        try {
            let { data } = await axios.get("https://back.igway.online/api/teachers");
            setTeachers(data.data);
            setIsLoading(false);

          } catch (err) {
            console.log(err);
          }
    }
    return <>
        {isLoading === true ?
            <div className="d-flex justify-content-center align-items-center spiner">
                <Circles
                    height="80"
                    width="80"
                    color="#FFBC15"
                    ariaLabel="circles-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
            </div>
            :
            <div>
                
                <div className="m-0 p-0 background">
                    <div className="position-relative background">
                        <img src="/Images/line.png" className="w-100 m-0 p-0 position-absolute line1 land-line" alt="" />
                        <img src="/Images/line2.png" className="w-100 m-0 p-0 position-absolute line2" alt="" />
                    </div>
        
                </div>
                <div className="position-relative after-line-teacher-land ">
                </div>
                <div className="flex-row background d-flex py-3">
                    <div className="col-md-6 d-flex flex-column  justify-content-center align-items-end">
                        <div className="w-75 ">
                            <h3 className="main-color">
                                {t("experiencedInst")}
                            </h3>
                            <p className="m-0">
                                {t("instGreat")}
                            </p>
                        </div>
                    
                    </div>
                    <div className="col-md-6 d-flex align-items-start  justify-content-start ">
                        <img src="/Images/land-teacher-img.png" className="w-75 " alt="Landing Teacher" />
                    </div>
                </div>
                <div className="background ">
        
                    <div className="row background m-0  pt-5 w-75 mx-auto">
                        {teachers.map((teacher, index) => {
                            return <LandingPageTeacherItem teacher={teacher} index={index}/>
                        })}
                    </div>
                </div>                
            </div>
        }
    </>
}
