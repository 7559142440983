import React from 'react'
import { Navigate } from 'react-router-dom'
export default function ProtectedRoute(props) {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user)
    {
        console.log(user);
        if (user.student.educationSystem === null) {
            console.log("asdasds");
            
            return <Navigate to={"/educatorregister"}/>
        }
    }
    if (localStorage.getItem("user"))
    {
        return props.children
    }
    else
    {
        return <Navigate to={"/login"}/>
    }
}
