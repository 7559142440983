import axios from "axios";
import React, { useEffect, useState } from "react";
import { Circles } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import ReactHelmet from "../../ReactHelmet/ReactHelmet";
import { useNavigate } from "react-router-dom";

export default function AttendanceTransactions() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [attendanceTransactions, setAttendanceTransactions] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const getAttendanceTransactions = async (page) => {
    setIsLoading(true);

    try {
      const token = localStorage.getItem("token");
      let { data } = await axios.get(
        `https://back.igway.online/api/user/transactions/refund?page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(data.data);
      setAttendanceTransactions(data.data);
      setTotalPages(data.meta.last_page);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getAttendanceTransactions();
    getCourses();
  }, []);
  const getCourses = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      let { data } = await axios.get(
        "https://back.igway.online/api/user/mini-courses",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCourses(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    console.log(selectedCourse, fromDate, toDate);
    try {
      const token = localStorage.getItem("token");
      let { data } = await axios.get(
        `https://back.igway.online/api/user/transactions/attendance?course=${selectedCourse}&dateRange=${fromDate},${toDate}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAttendanceTransactions(data.data);
      setTotalPages(data.meta.last_page);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  const handleCourseChange = async (e) => {
    const courseId = e.target.value;
    setSelectedCourse(courseId);
  };
  const handleFromDateChange = (event) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
  };
  const isFormComplete = selectedCourse && fromDate && toDate;
  useEffect(() => {
    getAttendanceTransactions(page);
  }, [page]);
  return (
    <>
      <ReactHelmet title={`${t("attendanceTransactions")} | IG WAY`} />

      {isLoading === true ? (
        <div className="d-flex justify-content-center align-items-center spiner">
          <Circles
            height="80"
            width="80"
            color="#FFBC15"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <div className="px-2 mx-md-5 p-4">
          <div className="bg-white p-3 rounded-3 shadow">
            <div className="">
              <div className="filter-container">
                <div className="filter-header d-flex align-items-center">
                  <img
                    src="/Images/filter.png"
                    className="search me-2"
                    alt=""
                  />
                  <h5>{t("filter")}</h5>
                </div>
                <hr />
                <form className="d-flex flex-column" onSubmit={handleSubmit}>
                  <div className=" container-fluid">
                    <div className="row w-100 mb-4">
                      <div className="col-md-3">
                        <div className="d-flex flex-column">
                          <label>{t("courseName")}</label>
                          <select
                            className="form-control"
                            value={selectedCourse}
                            onChange={handleCourseChange}
                          >
                            <option value="">Select Course</option>
                            {courses.map((course) => (
                              <option key={course.id} value={course.id}>
                                {course.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="d-flex flex-column">
                          <label>{t("from")}</label>
                          <input
                            type="date"
                            className="form-control"
                            value={fromDate}
                            onChange={handleFromDateChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="d-flex flex-column">
                          <label>{t("to")}</label>
                          <input
                            type="date"
                            className="form-control"
                            value={toDate}
                            onChange={handleToDateChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid">
                    <div className="row w-100">
                      <div className="col-md-12 col-lg-2 offset-lg-10">
                        <button
                          className=" btn btn-warning w-100"
                          variant="primary"
                          type="submit"
                          disabled={!isFormComplete}
                        >
                          {t("apply")}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <h4 className="mt-4">{t("transactionList")}</h4>
          <div className="d-flex justify-content-between">
            <div className="input-group mb-3 search-transaction"></div>
            <button
              className=" btn btn-warning py-2 mb-3 my-0 h-75"
              onClick={() => navigate("/student/wallet-refunds-request")}
              type="submit"
            >
              Refund Request
            </button>
          </div>
          <div className="bg-white shadow rounded-3 p-3  mb-3">
            <table className="table  table-bordered text-center ">
              <thead>
                <tr>
                  <th className="bg-light">{t("id")}</th>
                  <th className="bg-light">{t("courseName")}</th>
                  <th className="bg-light">{t("date2")}</th>
                  <th className="bg-light">{t("amount2")}</th>
                  <th className="bg-light">{t("currency")}</th>
                  <th className="bg-light">{t("status")}</th>
                </tr>
              </thead>
              <tbody>
                {attendanceTransactions.map((attendanceTransaction) => {
                  return (
                    <tr>
                      <td>{attendanceTransaction.id}</td>
                      <td>mdo</td>
                      <td>{attendanceTransaction.formatted_date}</td>
                      <td> {attendanceTransaction.value}</td>
                      <td>
                        {attendanceTransaction.currency}{" "}
                        {attendanceTransaction.value}
                      </td>
                      <td>{attendanceTransaction.status}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="pagination d-flex justify-content-center">
            <button
              className="btn btn-secondary me-2"
              disabled={page === 1}
              onClick={() => setPage(page - 1)}
            >
              {t("previous")}
            </button>
            <span>
              {t("page")} {page} {t("of")} {totalPages}
            </span>
            <button
              className="btn btn-secondary ms-2"
              disabled={page === totalPages}
              onClick={() => setPage(page + 1)}
            >
              {t("next")}
            </button>
          </div>
        </div>
      )}
    </>
  );
}
