import React,{useState, useEffect, useMemo} from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Circles } from 'react-loader-spinner';
import { usePrice } from '../../../PriceContext';
import { useWallet } from '../../../WalletContext';
import { useNavigate , useParams } from 'react-router-dom';

export default function Vodafone({ recharge, setRecharge }) {
    const { t } = useTranslation();
    const [textToCopy, setTextToCopy] = useState('');
    const [copyStatus, setCopyStatus] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const { wallet } = useWallet();
    const user = JSON.parse(localStorage.getItem("user") || '{}');
    const userId = user.id || null;
    const { price, selectedItems, withAssignments, type , newSelectedItemsIds} = usePrice();
    const [checkoutData, setCheckoutData] = useState(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const courseId = useParams().id;

    // get vodafone cash 
    const getVodafone = async () => {
      try {
        const token = localStorage.getItem("token");
        let { data } = await axios.get("https://back.igway.online/api/settings", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setPhoneNumber(data.data[0].value);
        setRecharge({ ...recharge, phone_number: data.data[0].value });
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    };

    useEffect(() => {
      getVodafone();
    },[getVodafone]);

    const onCopyText = () => {
        setCopyStatus(true);
        setTimeout(() => setCopyStatus(false), 2000); 
      };

      // Memoize checkout data to prevent unnecessary re-renders
      const memoizedCheckoutData = useMemo(() => {
        if (!userId || price === undefined) return null;
    
        let newCheckoutData = {
          type: type,
          payment_method:3 ,
          user_id: userId,
          with_assignments: withAssignments,
          value: price,
          phone_number: phoneNumber,
        };
    
        if (type === 1) { // Course
          newCheckoutData = {
            ...newCheckoutData,
            course_id: courseId,
          };
        } else if (type === 2) { // Topics
          newCheckoutData = {
            ...newCheckoutData,
            topics: newSelectedItemsIds,
          };
        } else if (type === 3) { // Subcourses
          newCheckoutData = {
            ...newCheckoutData,
            subcourses: newSelectedItemsIds,
          };
        } else if (type === 4) { // Lessons
          newCheckoutData = {
            ...newCheckoutData,
            lessons: newSelectedItemsIds,
          };
        }
    
        return newCheckoutData;
      }, [type, selectedItems, withAssignments, price, userId, newSelectedItemsIds, phoneNumber]);
    
      useEffect(() => {
        if (memoizedCheckoutData) {
          setCheckoutData(memoizedCheckoutData);
        }
      }, [memoizedCheckoutData]);
    

      const handleCheckout = async () => {
        const token = localStorage.getItem("token");
        console.log(checkoutData);
        if (checkoutData) {
          console.log("Checkout Data:", checkoutData);
        //   // console.log("Selected Items:", selectedItems);
          setLoading(true);
          try {
            let response = await axios.post("https://back.igway.online/api/checkout", checkoutData, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            console.log(response);
            setSuccessMsg(response.message);
            window.open(response?.request?.responseURL, '_blank');
            setLoading(false);
          }
          catch (err) {
            console.log(err?.response?.data?.errors);
            console.log(err?.response?.data?.message);
            setLoading(false);
            setError( err?.response?.data?.message || 'An error occurred');
          }
        } 
      };
      const handleShowModal = (e) => {
        e.preventDefault();
        if (price <= wallet) {
          handleCheckout(e);
        } else {
          setShowModal(true);
        }
      };
      const handleCloseModal = () => {
        setShowModal(false);
      };
      
      const rechargeWallet = () => {
        navigate("/student/wallet");
      };
  


      if (isLoading) {
        return <div className="d-flex justify-content-center align-items-center spiner">
           <Circles height="80" width="80" color="#FFBC15" ariaLabel="circles-loading" wrapperStyle={{}} wrapperClass="" visible={true}/>
                </div>;
      } 

  return (
    <>
        <div className="container mt-4 p-3 bg-white rounded-4 shadow ms-0">
            <label htmlFor="phone-number" className='form-label alltext-black-color h6'>{t("phone")}</label>
            <div className='position-relative'>
              <input type="text" name="phone-number" id="phone-number" className='form-control bg-light py-3 pe-5' value={phoneNumber}  onChange={(e) => setTextToCopy(e.target.value)} readOnly/>
              <CopyToClipboard text={phoneNumber} onCopy={onCopyText}>
              <i className="fa-regular fa-copy position-absolute top-50 end-0 translate-middle pe-1" style={{color: "#3d3d3d"}} ></i>
              </CopyToClipboard>
              {copyStatus && <p className='text-success position-absolute top-50 end-0 translate-middle pe-1'>{t("copyNumber")}</p>}
            </div>
            <p className='mt-3 text-center fw-bold'>{t("willPay")} {price}</p>
            {loading ? (
            <button className='w-100 btn btn-warning mt-4 fw-bold py-2 alltext-side-color shadow' disabled><i className="fa-solid fa-spinner fa-spin"></i></button>

            ) : (
            <button type="submit" className={`w-100 btn btn-warning mt-4 fw-bold py-2 alltext-side-color shadow`} onClick={handleShowModal}>
              Pay
            </button>    
           )}
          <div>
          {error && (
              <div className="text-center alert alert-danger mt-4 d-block" role="alert">
                {error}
              </div>
            )}
            {successMsg && (
              <div className="text-center alert alert-success mt-4 d-block" role="alert">
                {successMsg}
              </div>
            )}

          </div>
          {price > wallet && showModal && (
          <div className="modal fade show d-block igWallet-modal" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content rounded-4">
                <div className="modal-header justify-content-evenly">
                  <h5 className="text-modal-res modal-title ms-5 me-5" id="exampleModalLongTitle">{t("notCompletedPayment")}</h5>
                  <button type="button" className="close btn btn-main-color text-white fw-bold px-1 py-0 rounded ms-2" aria-label="Close" onClick={handleCloseModal}>
                    <span aria-hidden="true" className='p-1'>&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="d-flex flex-column align-items-center">
                    <img src="/Images/igwallet-girl.png" alt="No Money" />
                    <p className="text-modal-res alltext-black-color text-center">{t("notCompletedText")}</p>
                  </div>
                  <div className="modalBtns row">
                    <div className="col-md-6 pe-2">
                      <button className="btn-res-font btn btn-warning w-100 p-2 alltext-side-color fw-bold" onClick={rechargeWallet}>{t("confirm")}</button>
                    </div>
                    <div className="col-md-6 ps-0">
                      <button className="btn-res-font btn btn-outline-secondary w-100 p-2 alltext-side-color fw-bold" onClick={handleCloseModal}>{t("anotherPayment")}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        </div>
            
    </>
  )
}
