import React from 'react'
import { Link } from 'react-router-dom'
import ReactHelmet from '../ReactHelmet/ReactHelmet'
export default function Notfound() {
    return <>
        <ReactHelmet title={"Not Found"} />
        <div className="container">
            <div className='vh-100 d-flex justify-content-center align-items-center flex-column'>
                <img src="/Images/notfound.png" alt=""className='w-50' />
            <Link className='btn btn-warning w-25' to={"/student/home"}> Back To Home</Link>
            </div>
        </div>
    </>
}
