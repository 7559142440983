import React, { createContext, useState, useContext } from 'react';

const PriceContext = createContext();

export const PriceProvider = ({ children }) => {
  const [price, setPrice] = useState(0);
  const [selectedItems, setSelectedItems] = useState({
    course: null,
    section: [],
    unit: {},
    lesson: {},
  });
  const [withAssignments, setWithAssignments] = useState(false);
  const [type, setType] = useState(1);
  const [checkout, setCheckout] = useState(null);
  const [newSelectedItemsIds, setSelectedItemsIds] = useState({
    course: null,
    section: [],
    unit: {},
    lesson: {},
  });
  
  // Function to update the array of IDs of selected items
  const updateSelectedItemsIds = (newSelectedItemsIds) => {
    setSelectedItemsIds(newSelectedItemsIds);
  };
  

  // Function to update the price
  const updatePrice = (newPrice) => {
    setPrice(newPrice);
  };

  // Function to update selected items
  const updateSelectedItems = (newSelectedItems) => {
    setSelectedItems(newSelectedItems);
  };

  // Function to update withAssignments
  const updateWithAssignments = (value) => {
    setWithAssignments(value);
  };

  // Function to update type
  const updateType = (newType) => {
    setType(newType);
    console.log(type);
  };

  // Function to update checkout data
  const updateCheckout = (checkoutData) => {
    setCheckout(checkoutData);
  };



  return (
    <PriceContext.Provider value={{
      price, updatePrice, selectedItems, updateSelectedItems, withAssignments, updateWithAssignments,
      type, updateType, checkout, updateCheckout, newSelectedItemsIds, updateSelectedItemsIds
    }}>
      {children}
    </PriceContext.Provider>
  );
};

export const usePrice = () => {
  return useContext(PriceContext);
};
