import React, { useState ,useEffect} from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './calendar.css';
import CustomToolbar from './CustomToolbar';
import CustomHeader from './CustomHeader';
import CustomEvent from './CustomEvent';
import Spinner from './Spinner';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Circles } from "react-loader-spinner";
import { useNavigate } from 'react-router-dom';
import ReactHelmet from '../ReactHelmet/ReactHelmet';

export default function Schedule() { 
  const localizer = momentLocalizer(moment);
  const { t, i18n } = useTranslation();
  const [events, setEvents] = useState([]);
  const [nearestEvent,setNearestEvent]=useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    document.body.setAttribute('dir', i18n.language === 'ar' ? 'rtl' : 'ltr');
    moment.locale(i18n.language);
  }, [i18n.language]);
  //random 3 colors for events
  const getRandomColorSet = () => {
    const colorSets = [
      { backgroundColor: '#FFE6A8', color: '#FFBC15' },
      { backgroundColor: '#CCE0F6', color: '#00AF54' },
      { backgroundColor: '#FFE0D8', color: '#FF633D' }
    ];
    return colorSets[Math.floor(Math.random() * colorSets.length)];
  };
  useEffect(() => {
     getSchedule(); 
  }, []);

  const eventColor = (event, start, end, isSelected) => {
    const { colorSet } = event;
    if (!colorSet) {
      return { style: {} };
    }
    const { backgroundColor, color } = colorSet;
    return {
      style: { backgroundColor,color,border: isSelected ? '1px solid black' : 'none'},
    };
  };
  useEffect(() => {
    if (events.length > 0) {
      const sortedEvents = [...events].sort((a, b) => new Date(a.start) - new Date(b.start));
      const nearest = sortedEvents.find(event => new Date(event.start) > new Date());
      setNearestEvent(nearest);
    }
    // setIsLoading(false);
  }, [events]);

  const convertedEvents = events.map((event) => ({
    ...event,
    start: new Date(event.start),
    end: new Date(event.end),
  }));
  // get schedule 
  const getSchedule = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      let { data } = await axios.get('https://back.igway.online/api/user/schedule', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const formattedEvents = data.data.map(event => ({
        start: new Date(event.start_at),
        end: new Date(event.end_at),
        title: event.lesson,
        course: event.course,
        teacher: event.teacher,
        colorSet: getRandomColorSet(),
        zoomLink: event.zoom_link
      }));
      setEvents(formattedEvents);
      setIsLoading(false);
      console.log(formattedEvents);
    } catch (err) {
      setIsLoading(false);
    }
  };
  // start button
  const handleStart = () => {
    if (nearestEvent) {
      // navigate(`/student/recordingdetails`);
      // go to zoom link
      window.open(nearestEvent.zoomLink, '_blank');
    }
  };
  if (isLoading || !events) {
    return <div className="d-flex justify-content-center align-items-center spiner">
    <Circles height="80" width="80" color="#FFBC15" ariaLabel="circles-loading" wrapperStyle={{}} wrapperClass="" visible={true} />
  </div>;
}


  return (
  <div className="container-fluid">
        <ReactHelmet title={`${t('schedule')} | IG WAY`} />
    
      <div className="row">
        <div className="col-md-8 calendar-container me-4 order-2 order-md-1">
          <div style={{ height: '80vh' }}>
            <Calendar className='w-100'
              localizer={localizer}
              events={convertedEvents}
              startAccessor="start"
              endAccessor="end"
              views={['week']}
              defaultView='week'
              selectable
              eventPropGetter={eventColor}
              scrollToTime={new Date(1970, 1, 1, 11, 30, 0)} // Scroll to 11:30 AM
              components={{toolbar: CustomToolbar,event: CustomEvent,header : CustomHeader}}/>
          </div>
        </div>
        <div className="col-md-3 schedule rounded-4 p-4 order-1 order-md-2 schedule-responsive">
          <h6 className="alltext-black-color fw-bold pb-3 text-center">{t("nextLesson")}</h6>
          <div className='d-flex justify-content-center'>
            <Spinner startTime={nearestEvent?.start}  />
          </div>
          {nearestEvent ? (
            <div className="text">
              <div>
                <p className="text-muted">{t("instructor")}</p>
                <h6>{nearestEvent?.teacher}</h6>
              </div>
              <div>
                <p className="text-muted">{t("course")}</p>
                <h6>{nearestEvent?.course}</h6>
              </div>
              <div>
                <p className="text-muted">{t("lesson")}</p>
                <h6>{nearestEvent?.title}</h6>
              </div>
              <button className="text-muted btn schedule-btn w-100 p-2 alltext-side-color fw-bold py-3" onClick={handleStart}>
                {t("start")}
              </button>
          </div>
          ) : (
            <div className="text">
              <div>
                <h6 className="text-center mt-5">{t("noLessons")}</h6>
              </div>
            </div>
          )}     
        </div>
      </div>
    </div>
  );
}