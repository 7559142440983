import React, { useState,useEffect } from 'react';
import IGWallet from './IGWallet/IGWallet';
import Credit from './Credit/Credit';
import Styles from './Checkout.module.css';
import Vodafone from './Vodafone/Vodafone';
import { useNavigate, useParams } from 'react-router-dom';
import DropDown from './DropDown';
import { useTranslation } from 'react-i18next';
import { Circles } from 'react-loader-spinner';
import axios from 'axios';
import ReactHelmet from '../ReactHelmet/ReactHelmet';
import { usePrice} from '../../PriceContext';
import { useGetRequest } from '../../Hooks/useGetRequest';

export default function Checkout() {
    const { t } = useTranslation();
    const [transactionType, setTransactionType] = useState("IG Wallet");
    const [couponCode, setCouponCode] = useState("");
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [course, setCourse] = useState({});
    const [selectedOption, setSelectedOption] = useState({});
    const courseId = useParams().id;
    const { price } = usePrice();
    const [originalPrice, setOriginalPrice] = useState(0);
    const [loading , setLoading] = useState(false);
    const [error , setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState("");

    useEffect(() => {
        getCourse();
    }, []);
    const getCourse = async () => {
        try {
            const token = localStorage.getItem("token");
            let { data } = await axios.get(
                `https://back.igway.online/api/courses/${courseId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setCourse(data.data);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    const goBack = () => {
      navigate(-1); 
    };
    const handleTransactionTypeChange = (type) => {
        setTransactionType(type);
    };
    //coupon code 
    const handleCouponCodeChange = (event) => {
        const { value } = event.target;
        setOriginalPrice(value);
    };
    const handleApplyCoupon = async() => {
      const token = localStorage.getItem("token");
      setLoading(true);
      try {
        const { data } = await axios.get(`https://back.igway.online/api/user/coupon/123/validate?original_price=${originalPrice}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(data.data);
        setLoading(false);
        setShowModal(true);
        setData(data.data);
    }
    catch (err) {
        console.log(err);
        setLoading(false);
    }
    }
    const handleOptionSelect = (option) => {
        setSelectedOption(option);
      };


    if (isLoading) {
        return <div className="d-flex justify-content-center align-items-center spiner">
                <Circles height="80" width="80" color="#FFBC15" ariaLabel="circles-loading" wrapperStyle={{}} wrapperClass="" visible={true}/>
              </div>;
            } 
    return (
        <div className='mt-3 container-fluid px-5'>
            <ReactHelmet title={`${t("checkout")} | IG WAY`} />

            <div className="row">
                <div className="col-md-8">
                    <div className='d-flex align-items-center pb-3'>
                        <i onClick={goBack} className={`fa-solid left-arrow-checkout fa-arrow-left backIcon ms-3`}></i>
                        <p className={`ms-5 h1 fs-5 fw-bold alltext-black-color`}>{t("checkout")}</p>
                    </div>
                    <div>
                        <p className={`fs-6 fw-bold alltext-black-color`}>{t("payFor")}</p>
                        {/* custom drop down */}
                        <DropDown course={course} onOptionSelect={handleOptionSelect}/>
                    </div>
                    <p className={`${Styles.selectPayment} fs-6 fw-bold mt-4`}>{t("paymentMethod")}</p>
                    <div className={Styles.paymentMethod}>
                        <div className={`d-flex justify-content-between w-100 pb-4 flex-wrap`}>
                            <div className="row w-100">
                                <div className="col-md-4">
                                    <button className={`${Styles.transactionBtn} transactionBtn d-flex justify-content-center w-100 py-3 ${transactionType === "IG Wallet" ? Styles.active : ""}`} onClick={() => handleTransactionTypeChange("IG Wallet")} >
                                        <input type="radio" name="wallet" id="wallet" value="IG Wallet" checked={transactionType === "IG Wallet"}  onChange={() => handleTransactionTypeChange("IG Wallet")}/>
                                        <label className='' htmlFor="wallet">{t("igWallet")}</label>
                                    </button>
                                </div>
                                <div className="col-md-4 ">
                                    <button className={`${Styles.transactionBtn} transactionBtn d-flex w-100 justify-content-center py-3 ${transactionType === "Credit" ? Styles.active : ""}`}onClick={() => handleTransactionTypeChange("Credit")}>
                                        <input type="radio" name="credit" id="credit" value="Credit" checked={transactionType === "Credit"} onChange={() => handleTransactionTypeChange("Credit")}/>
                                        <label className='' htmlFor="credit">{t("debit/credit")}</label>
                                    </button>
                                </div>
                                <div className="col-md-4">
                                    <button className={`${Styles.transactionBtn} transactionBtn d-flex justify-content-center w-100 py-3 ${transactionType === "Vodafone Cash" ? Styles.active : ""}`} onClick={() => handleTransactionTypeChange("Vodafone Cash")}>
                                        <input type="radio" name="vodafone" id="vodafone" value="Vodafone Cash" checked={transactionType === "Vodafone Cash"} onChange={() => handleTransactionTypeChange("Vodafone Cash")}/>
                                        <label className='' htmlFor="vodafone">{t("vodafoneCash")}</label>
                                    </button>
                                </div>
                            </div>
                        
                    </div>
                    </div>
                    {/* selected */}
                    {transactionType === "IG Wallet" ? <IGWallet course={course}/> : transactionType === "Credit" ? <Credit buttonText={t("proceedCheckout")} course={course}/> : transactionType === "Vodafone Cash" ? <Vodafone course={course}/> : null}
                </div>
                <div className="col-md-4">
                    <div className="card shadow mb-4 px-5 border-0">
                        <div className="card-body ">
                            <h5 className="card-title">{t("courseInfo")}</h5>
                            <hr />
                            <div>
                                <h6>{t("eduSystem")}</h6>
                                <p className='text-muted'>{course?.educationSystem?.name}</p>
                            </div>
                            <div>
                                <h6>{t("board")}</h6>
                                <p className='text-muted'>{course?.board?.name}</p>
                            </div>
                            <div>
                                <h6>{t("courseLevel")}</h6>
                                <p className='text-muted'>{course?.level?.name}</p>
                            </div>
                            <div>
                                <h6>{t("totalEnrollment")}</h6>
                                <p className='text-muted'>{  course?.total_lessons_price}</p>
                                </div>
                        </div>    
                    </div>
                    <div>
                        <h5>{t("payFor")}</h5>
                        <div className='row my-4'>
                            <div className='col-md-6'>
                                <button className="card shadow w-100 transactionPayForBtn border-0 p-0">
                                    <div className="card-body align-self-center">
                                        <h5 className="card-title">{course?.currency} {course?.price-course.total_lessons_price}</h5>
                                        <hr />
                                        <h6>{t("perCourse")}</h6>
                                    </div>    
                                </button>
                            </div>
                            <div className='col-md-6'>
                                <button className="card shadow w-100 transactionPayForBtn border-0">
                                    <div className="card-body align-self-center">
                                        <h5 className="card-title">{course?.currency} {course?.topics[0]?.price || 0}</h5>
                                        <hr />
                                        <h6>{t("perLesson")}</h6>
                                    </div>    
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h6>{t("coupon")}</h6>
                        <div className={`input-group my-4 ${Styles.coupon}`}>
                            <input type="text" className="form-control" placeholder={t("enterCoupon")} aria-label="Enter Coupon" aria-describedby="basic-addon2" onChange={handleCouponCodeChange}/>
                            <div className="input-group-append">
                                {loading ?  ( 
                                    <button className="coupon-button btn btn-warning alltext-side-color fw-bold h-100" type="button" disabled><i className="fa fa-spinner fa-spin"></i></button>
                                ) : ( 
                                    <button className="coupon-button btn btn-warning alltext-side-color fw-bold h-100" type="button" onClick={handleApplyCoupon}>{t("applyCoupon")}</button>
                                )}
                            </div>
                        </div>
                    </div>
                    { showModal && (
                        <div className=" modal fade show d-block igWallet-modal" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content rounded-4">
                                <div className="modal-body">
                                <div className="d-flex flex-column align-items-center">
                                    <img src="/Images/success.png" className='w-50' alt="Coupon Code Added Successfully" />
                                    <p className="text-modal-res alltext-black-color text-center fw-bold my-3">Coupon Code Added Successfully</p>
                                    <div className="d-flex flex-column align-items-center mb-5">
                                        <p>Discount Percentage is {data?.discount_value}</p>
                                        <p>Discount Amount is {data?.discount_amount}</p>
                                        <div>
                                            <span> <span className='fw-bold  alltext-black-color'>Price Before Discount </span>: {data?.price_before_discount}</span> <span className='ms-3'><span className='fw-bold  alltext-black-color'>Price After Discount :</span> {data?.price_after_discount}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <button className="btn-res-font btn btn-warning w-25 p-2 alltext-side-color fw-bold" onClick={() => setShowModal(false)}>Ok</button>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    )}
                </div>    
            </div>
        </div>
    );
}
