import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import ReactHelmet from '../ReactHelmet/ReactHelmet';

export default function NewPassword() {
  const { t , i18n} = useTranslation();
    const navigate = useNavigate();
    const [initialValues, setInitialValues] = useState([]);
    const [isloading, setIsloading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errors, setErrors] = useState();
  



  
  
    let validationSchema=Yup.object().shape({
        password: Yup.string()
      .matches(
        /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/,
        t('validation')
      )
      .required(t('required')),  
    password_confirmation: Yup.string().oneOf([Yup.ref("password")]).required(t('required')),
    });
    const formik = useFormik({
      initialValues: {
        password: '',
        password_confirmation: '',
      },
      validationSchema: validationSchema
      ,
      onSubmit: handleNext
    });
    async function handleNext(values) {
        setIsloading(true);
        const token = localStorage.getItem('token_verify');
        let array = { ...values, "token": token };
        console.log(array);
    let response = await axios.post(`https://back.igway.online/api/auth/password/update`, array, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    }).catch(err => {
      setIsloading(false);
      setErrors(err.response.data.message);
      console.log(err);
    });
    console.log(response);
    }
  
    return <>
        <div className=" container-fluid p-0 m-0 ">
          <ReactHelmet title={`${t('login') } | IG WAY`} />
        <div className="row p-0 m-0">
            <div className="col-md-6 p-0 m-0">
            <img src="/Images/reg-image3.png" className='w-100 vh-100 bg-side-color p-0 m-0 rounded-end-4 signupImg' alt="" />
            </div>
            <div className={`col-md-6 ${i18n.language === 'ar' ? 'pe-5' : ''}`}>
            <div className={`w-100 text-end ${i18n.language === 'ar' ? 'd-flex justify-content-end' : ''}`}>
                <img src="/Images/reg-logo.png" className='mx-5 mt-2' alt="" />
            </div>
            <div className='row authenticationBody'>
                <div className="col-md-10 offset-md-1 ">
                <h1 className=' ms-5 mb-2'>{t('createPass')}</h1>
                <p className=' ms-5 mb-2'>{t('createText')}</p>
                <form className="row mt-4" onSubmit={formik.handleSubmit}>  
                    <div className="col-md-10 offset-md-1 ps-0">
                        
                    <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    {t('Password')}
                  </label>
                  <div className="input-group">
                    <input
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      className="form-control pe-5"
                      placeholder={t('Password')}
                                  />
                                    
                    
                    <button
                      className="btn border-0  position-absolute end-0 top-50 translate-middle-y"
                      type="button "
                        onClick={(event) => { 
                          event.preventDefault();
                          setShowPassword(!showPassword)
                        } }
                    >
                      {showPassword ? <i className=" fa-regular fa-eye"></i> : <i className=" fa-solid fa-eye-slash"></i>}
                    </button>
                  </div>
                </div>
                { formik.touched.password && formik.errors.password ? <div className="alert alert-danger p-1">{formik.errors.password}</div> : null }
                <div className="mb-3">
                  <label htmlFor="password_confirmation" className="form-label">{t('confirmPass')}</label>
                    <div className="input-group">
                  <input
                   type={showConfirmPassword ? 'text' : 'password'}
                   name='password_confirmation' 
                   onChange={formik.handleChange} 
                   onBlur={formik.handleBlur} 
                   value={formik.values.password_confirmation} 
                   className="form-control pe-5" 
                   placeholder={t('passConfirmationPlaceholder')} 
                    />
                    <button
                      className="btn border-0  position-absolute end-0 top-50 translate-middle-y"
                      type="button "
                      onClick={(event) => { 
                        event.preventDefault();
                        setShowConfirmPassword(!showConfirmPassword);
                      } }
                    >
                      {showConfirmPassword ? <i className=" fa-regular fa-eye"></i> : <i className=" fa-solid fa-eye-slash"></i>}
                      </button>
                    </div>

                  
                
                  </div>
                { formik.touched.Parent_Phone && formik.errors.password_confirmation ? <div className="alert alert-danger p-1">{formik.errors.password_confirmation}</div> : null }
                  
                    </div>
                    
                    
                    <div className={`row p-0 ${i18n.language === 'ar' ? 'justify-content-center' : ''}`}>
                    <div className="col-md-10 offset-md-1  pe-0">
                        <button type="submit" disabled={!(formik.isValid && formik.dirty)}  className="btn btn-warning w-100 p-3 m-0">{t('submit')}</button>
                    </div>
                    </div>
                </form>
    
    
                
                </div>
            </div>
            </div>
        </div>
        </div>
  </>
}
