import React from 'react'
import { json, Navigate } from 'react-router-dom'

export default function EducationSystemRoute({ children }) {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user)
        {
            if (user.student.educationSystem !== null) {
                
                return <Navigate to={"/student/home"}/>
            }
            return children;
        }
    else
    {
        return <Navigate to={"/login"}/>
    }
}
