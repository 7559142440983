import React from "react";
import { Link  } from "react-router-dom";

export default function RelatedRecordingItem({ recording, index }) {
  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <Link to={`/student/recordingdetails/${recording.id}`} >
          <img src={recording.image.url} alt="" className='record-img me-2 rounded-4' />
        </Link>
          
                <div>
                  <p className='fw-bold'>
                    {recording.name}
                  </p>
                  
                  <div className="d-flex justify-content-between align-items-center">
                    <p>{recording.teacher}</p>
                    <p>{ recording.created_at}</p>
                  </div>
                </div>
              </div>
    </>
  );
}
