import React from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import moment from 'moment';

export default function Spinner({ startTime, onTimeEnd }) {
  const now = moment();
  const start = moment(startTime);

  // Check if start time is valid
  if (!start.isValid()) {
    return <div className="timer-spinner-content py-5 text-muted fw-bold">No Upcoming Lessons</div>;
  }

  const duration = moment.duration(start.diff(now)).asSeconds();

  const formatTime = (seconds) => {
    const duration = moment.duration(seconds, 'seconds');
    const hours = String(Math.floor(duration.asHours())).padStart(2, '0');
    const minutes = String(duration.minutes()).padStart(2, '0');
    const secs = String(duration.seconds()).padStart(2, '0');
    return `${hours}:${minutes}:${secs}`;
  };

  return (
    <CountdownCircleTimer
      isPlaying
      duration={duration}
      onComplete={onTimeEnd}  // Call the onTimeEnd function when time runs out
      colors={['#F7B801', '#F7B801', '#A30000', '#A30000']}
      colorsTime={[7, 5, 2, 0]}
      size={150}
      strokeWidth={12}
    >
      {({ remainingTime }) => (
        <div className="timer-spinner-content">
          <div
            className="time bg-white rounded-circle d-flex justify-content-center align-items-center px-3 shadow text-muted fw-bold"
            style={{ paddingTop: '35%', paddingBottom: '35%' }}
          >
            {formatTime(remainingTime)}
          </div>
        </div>
      )}
    </CountdownCircleTimer>
  );
}
