import React, { useState, useRef, useEffect } from 'react';
import $ from 'jquery';
import Styles from './Checkout.module.css';
import { useTranslation } from 'react-i18next';
import { usePrice } from '../../PriceContext.js';

export default function DropDown({course, onOptionSelect }) {
  const { t } = useTranslation();
  const { updatePrice, selectedItems, updateSelectedItems, withAssignments, updateWithAssignments, updateType, updateSelectedItemsIds } = usePrice();
  const [mood, setMood] = useState('course');
  const [selectedOption, setSelectedOption] = useState(t('cardholderName'));
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedSections, setSelectedSections] = useState({});
  const [openSubdropdown, setOpenSubdropdown] = useState('');
  const [openUnitsDropdown, setOpenUnitsDropdown] = useState('');
  const [openTopicsDropdown, setOpenTopicsDropdown] = useState('');
  const [openSubcoursesDropdown, setOpenSubcoursesDropdown] = useState('');
  const [openLessonsDropdown, setOpenLessonsDropdown] = useState('');
  const dropdownMenuRef = useRef(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [choosenIDs, setChoosenIDs] = useState([]);
  const [assignmentPrice, setAssignmentPrice] = useState(course.assignments_price || 0);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(prev => !prev);
  };

  const options = [
    {
      value: 'course',
      label: t('payCourse'),
      type: 1,
      price: course.price,
    },
    {
      value: 'section',
      label: t('payTopic'),
      type: 2,
      sections: course.topics?.map((topic) => ({
        id: topic?.id,
        name: topic?.name,
        price: topic?.price,
        is_open: topic?.is_open,
      })),
    },
    {
      value: 'unit',
      label: t('payUnit'),
      type: 3,
      sections: course.topics?.map((topic) => ({
        id: topic?.id,
        name: topic?.name,
        units: topic?.subcourses?.map((subcourse) => ({
          id: subcourse?.id,
          name: subcourse?.name,
          price: subcourse?.price,
          is_open: subcourse?.is_open,
        })),
      })),
    },
    {
      value: 'lesson',
      label: t('payLesson'),
      type: 4,
      sections: course.topics?.map((topic) => ({
        id: topic?.id,
        name: topic?.name,
        subcourses: topic?.subcourses?.map((subcourse) => ({
          id: subcourse?.id,
          name: subcourse?.name,
          lessons: subcourse?.lessons?.map((lesson) => ({
            id: lesson?.id,
            name: lesson?.name,
            price: lesson?.price,
            is_open: lesson?.is_open,
          })),
        })),
      })),
    },
  ];
  
  

  const handleOptionClick = (option) => {
    setSelectedOption(option.label);
    setMood(option.value);
    onOptionSelect(option);

    // Update the selected type in the context
    updateType(option.type);

    // Reset other dropdowns when a new option is selected
    setSelectedSections({});
    setOpenSubdropdown(option.sections ? (openSubdropdown === option.value ? '' : option.value) : '');
    setOpenUnitsDropdown('');
    setOpenTopicsDropdown('');
    setOpenSubcoursesDropdown('');
    setOpenLessonsDropdown(option.value === 'lesson' ? 'all' : '');

    // Update the selected items in the context
    updateSelectedItems(prev => ({
      ...prev,
      [option.value]: []
    }));
    // Update the selected type in the context
    updateType(option.type);

    // Reset other dropdowns when a new option is selected
    setSelectedSections({});
    setOpenSubdropdown(option.sections ? (openSubdropdown === option.value ? '' : option.value) : '');
    setOpenUnitsDropdown('');
    setOpenTopicsDropdown('');
    setOpenSubcoursesDropdown('');
    setOpenLessonsDropdown(option.value === 'lesson' ? 'all' : '');

    // Update the selected items in the context
    updateSelectedItems(prev => ({
      ...prev,
      [option.value]: []
    }));
  };

  
  const calculateTotalPrice = () => {
    let total = 0;
  
  
    if (mood === 'course') {
      total = course.price-course.total_lessons_price;
    } else {
        // Calculate total for topics sections
        if (selectedSections.section) {
          const sectionOption = options.find((opt) => opt.value === 'section');
          if (sectionOption && sectionOption.sections) {
            selectedSections.section.forEach((sectionId) => {
              const section = sectionOption.sections.find((sec) => sec.id === sectionId);
              if (section) total += section.price || 0;
            });
          }
        }
     // Calculate total for units
     if (selectedSections.unit) {
      const sectionOption = options.find((opt) => opt.value === "unit");
      if (sectionOption && sectionOption.sections) {          
        Object.entries(selectedSections.unit).forEach(
          ([topicId, subCoursesIds]) => {
            const section = sectionOption.sections.find(
              //section =topic > subcourse
              (sec) => sec.id == topicId
            ) || { price: 0 };
            section.units.forEach((subCourse) => {
              if (subCoursesIds.includes(subCourse.id)) {
                total += subCourse.price || 0;
              }
            });
          }
        );
      }
    }

    // Calculate total for lessons
    if (selectedSections.lesson) {
      const lessonOption = options.find((opt) => opt.value === "lesson");
      if (lessonOption && lessonOption.sections) {
        Object.entries(selectedSections.lesson).forEach(
          ([sectionId, subcourses]) => {
            const section = lessonOption.sections.find(
              (sec) => sec.id == sectionId
            ) || { subcourses: [] };
            Object.entries(subcourses).forEach(([subcourseId, lessonIds]) => {
              const subcourse = section.subcourses.find(
                (sub) => sub.id == subcourseId
              ) || { lessons: [] };
              lessonIds.forEach((lessonId) => {
                const lesson = subcourse.lessons.find(
                  (l) => l.id == lessonId
                ) || { price: 0 };
                total += lesson.price || 0;
              });
            });
          }
        );
      }
    }
    }
      // Add assignment price if applicable
      if (withAssignments) {
        total += assignmentPrice;
      }
    setTotalPrice(total);
    updatePrice(total);
  };

  useEffect(() => {
    if (isDropdownOpen) {
      $(dropdownMenuRef.current).slideDown();
    } else {
      $(dropdownMenuRef.current).slideUp();
    }
  }, [isDropdownOpen]);

  useEffect(() => {
    calculateTotalPrice();
  }, [selectedSections, mood, withAssignments]);

  const handleUnitsToggle = (sectionName) => {
    setOpenUnitsDropdown(openUnitsDropdown === sectionName ? '' : sectionName);
  };

  const handleTopicsToggle = (sectionName) => {
    setOpenTopicsDropdown(openTopicsDropdown === sectionName ? '' : sectionName);
  };
  const handleSubcoursesToggle = (sectionName) => {
    setOpenSubcoursesDropdown(openSubcoursesDropdown === sectionName ? '' : sectionName);
  };

  const handleLessonsToggle = (subcourseName) => {
    setOpenLessonsDropdown(openLessonsDropdown === subcourseName ? '' : subcourseName);
  };

  const handleCheckboxChange = (optionValue, sectionId, unitId, isDisabled = false) => {
    // Skip state update if the checkbox is disabled
    if (isDisabled) return;
  
    setSelectedSections((prev) => {
      const updatedSections = { ...prev };
  
      if (unitId) {
        updatedSections[optionValue] = {
          ...updatedSections[optionValue],
          [sectionId]: updatedSections[optionValue]?.[sectionId] ? [...updatedSections[optionValue][sectionId]] : [],
        };
  
        const section = updatedSections[optionValue][sectionId];
  
        if (section.includes(unitId)) {
          updatedSections[optionValue][sectionId] = section.filter((id) => id !== unitId);
        } else {
          updatedSections[optionValue][sectionId].push(unitId);
        }
      } else {
        const prevSelection = updatedSections[optionValue] || [];
        const newSelection = prevSelection.includes(sectionId)
          ? prevSelection.filter((id) => id !== sectionId)
          : [...prevSelection, sectionId];
        updatedSections[optionValue] = newSelection;
      }
  
      // Calculate unique IDs
      const newChosenIds = [
        ...new Set(
          Object.values(updatedSections).flatMap((val) =>
            typeof val === 'object' && val !== null
              ? Object.values(val).flat()
              : val
          )
        ),
      ];
  
      // Update the global state
      updateSelectedItemsIds(newChosenIds);
      setChoosenIDs(newChosenIds);
  
      return updatedSections;
    });
  };
  
  const handleSubcourseChange = (optionValue, sectionId, subcourseId, lessonId, isDisabled = false) => {
    // Skip state update if the checkbox is disabled
    if (isDisabled) return;
  
    setSelectedSections((prev) => {
      const updatedSections = { ...prev };
  
      updatedSections[optionValue] = {
        ...updatedSections[optionValue],
        [sectionId]: updatedSections[optionValue]?.[sectionId]
          ? { ...updatedSections[optionValue][sectionId] }
          : {},
      };
  
      updatedSections[optionValue][sectionId][subcourseId] = updatedSections[optionValue][sectionId][subcourseId]
        ? [...updatedSections[optionValue][sectionId][subcourseId]]
        : [];
  
      const subcourse = updatedSections[optionValue][sectionId][subcourseId];
  
      if (subcourse.includes(lessonId)) {
        updatedSections[optionValue][sectionId][subcourseId] = subcourse.filter((id) => id !== lessonId);
      } else {
        updatedSections[optionValue][sectionId][subcourseId].push(lessonId);
      }
  
      // Calculate unique IDs
      const newChosenIds = [
        ...new Set(
          Object.values(updatedSections).flatMap((val) =>
            typeof val === 'object' && val !== null
              ? Object.values(val).flatMap((subval) =>
                  Array.isArray(subval) ? subval : Object.values(subval).flat()
                )
              : val
          )
        ),
      ];
  
      // Update the global state
      updateSelectedItemsIds(newChosenIds);
      setChoosenIDs(newChosenIds);
  
      return updatedSections;
    });
  };
  

  const handleAssignmentsChange = (event) => {
    const isChecked = event.target.checked;
    updateWithAssignments(isChecked);
    calculateTotalPrice();
  };
  const getPlaceholderText = () => {
    const finalPrice = course.price - course.total_lessons_price;
    if (mood === 'course') {
      return `${t('payCourse')}: ${finalPrice} ${course.currency}`;
    }
    if (mood === 'lesson') {
      return `${t('payLesson')}: ${totalPrice} ${course.currency}`;
    }

    if (mood === 'subcourse') {
      return `${t('paySubcourse')}: ${totalPrice} ${course.currency} `;
    }

    if (mood === 'section') {
      return `${t('payTopic')}:  ${totalPrice} ${course.currency}`;
    }


    const selectedItems = Object.entries(selectedSections).flatMap(([optionValue, itemObj]) => {
      if (Array.isArray(itemObj)) {
        return itemObj;
      } else if (typeof itemObj === 'object' && itemObj !== null) {
        return Object.entries(itemObj).flatMap(([sectionName, subcourses]) => {
          if (Array.isArray(subcourses)) {
            return subcourses.map(subcourseName => `${sectionName}: ${subcourseName}`);
          } else {
            return Object.entries(subcourses).flatMap(([subcourseName, lessons]) => {
              if (Array.isArray(lessons)) {
                return lessons.map(lessonName => `${sectionName}: ${subcourseName}: ${lessonName}`);
              } else {
                return [];
              }
            });
          }
        });
      }
      return [];
    });

    return selectedItems.length > 0 ? selectedItems.join(', ') : t('selectOption');
  };

  return (
    <div className={`${Styles.dropdown}`}>
      <div
        className={`${Styles.dropdownHeader} cursor d-flex justify-content-between bg-white rounded-4 shadow p-3`}
        onClick={handleDropdownToggle}
      >
        {getPlaceholderText()}
        <i className={`fa-solid ${isDropdownOpen ? 'fa-angle-up' : 'fa-angle-down'}`} style={{ color: '#c5c5c5' }}></i>
      </div>
      <div ref={dropdownMenuRef} className={`${Styles.dropdownMenu} ${isDropdownOpen ? 'd-block' : 'd-none'}`}>
        {options.map((option) => (
          <div key={option.value}>
            <div
              className={`${Styles.dropdownItem} d-flex justify-content-between`}
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
              {option.sections && (
                <div>
                  <i className={`fa-solid ${openSubdropdown === option.value ? 'fa-angle-up' : 'fa-angle-down'}`} style={{ color: '#c5c5c5' }}></i>
                </div>
              )}
            </div>
            {option.sections && openSubdropdown === option.value && (
              <div className={`${Styles.subdropdownMenu} d-flex flex-column ps-3`}>
                {option.value === 'section' &&
                  option.sections.map((section) => (
                    section.name ? (
                      <label key={section.id} className={Styles.checkboxLabel}>
                        <input
                          type="checkbox"
                          checked={selectedSections[option.value]?.includes(section.id) || false}
                          onChange={() => handleCheckboxChange(option.value, section.id)}
                          disabled={section.is_open === true}/>
                        {section.name}
                      </label>
                    ) : null
                  ))}
                {option.value === 'unit' &&
                  option.sections.map((section) => (
                    section.name ? (
                      <div key={section.id}>
                        <div
                          className={`${Styles.subdropdownItem} d-flex align-items-center cursor`}
                          onClick={() => handleUnitsToggle(section.id)}
                        >
                          {section.name}
                          <i className={`fa-solid ${openUnitsDropdown === section.id ? 'fa-angle-up' : 'fa-angle-down'}`} style={{ color: '#c5c5c5' }}></i>
                        </div>
                        {openUnitsDropdown === section.id && (
                          <div className={`${Styles.subdropdownMenu} d-flex flex-column ps-3`}>
                            {section.units.map((unit) => (
                              unit.name ? (
                                <label key={unit.id} className={Styles.checkboxLabel}>
                                  <input
                                    type="checkbox" 
                                    checked={selectedSections[option.value]?.[section.id]?.includes(unit.id) || false}
                                    onChange={() => handleCheckboxChange(option.value, section.id, unit.id)}
                                    disabled={unit.is_open === true}/>
                                  {unit.name}
                                </label>
                              ) : null
                            ))}
                          </div>
                        )}
                      </div>
                    ) : null
                  ))}
                {option.value === 'lesson' &&
                  option.sections.map((section) => (
                    section.name ? (
                      <div key={section.id}>
                        <div
                          className={`${Styles.subdropdownItem} d-flex cursor align-items-center`}
                          onClick={() => handleTopicsToggle(section.id)}
                        >
                          {section.name}
                          <i className={`fa-solid ${openTopicsDropdown === section.id ? 'fa-angle-up' : 'fa-angle-down'}`} style={{ color: '#c5c5c5' }}></i>
                        </div>
                        {openTopicsDropdown === section.id && (
                          <div className={`${Styles.subdropdownMenu} d-flex flex-column ps-3`}>
                            {section.subcourses.map((subcourse) => (
                              subcourse.name ? (
                                <div key={subcourse.id}>
                                  <div
                                    className={`${Styles.subdropdownItem} d-flex cursor align-items-center`}
                                    onClick={() => handleSubcoursesToggle(subcourse.id)}
                                  >
                                    {subcourse.name}
                                    <i className={`fa-solid ${openSubcoursesDropdown === subcourse.id ? 'fa-angle-up' : 'fa-angle-down'}`} style={{ color: '#c5c5c5' }}></i>
                                  </div>
                                  {openSubcoursesDropdown === subcourse.id && (
                                    <div className={`${Styles.subdropdownMenu} d-flex flex-column ps-3`}>
                                      {subcourse.lessons.map((lesson) => (
                                        lesson.name ? (
                                          <label key={lesson.id} className={Styles.checkboxLabel}>
                                            <input className={`${lesson.is_open === true ? '' : 'cursor'}`}
                                              type="checkbox" disabled={lesson.is_open === true}
                                              checked={selectedSections[option.value]?.[section.id]?.[subcourse.id]?.includes(lesson.id) || false}
                                              onChange={() => handleSubcourseChange(option.value, section.id, subcourse.id, lesson.id)}
                                            />
                                            {lesson.name}
                                          </label>
                                        ) : null
                                      ))}
                                    </div>
                                  )}
                                </div>
                              ) : null
                            ))}
                          </div>
                        )}
                      </div>
                    ) : null
                  ))}
              </div>
            )}
          </div>
        ))}
      </div>
      {/* appear when the choosen or inital value is pay for course  */}
      {mood === 'course' && (
        <div className='mt-4 ps-2'>
          <input type="checkbox" name="with_assignments" checked={withAssignments} onChange={handleAssignmentsChange} />
          <label htmlFor="with_assignments" className='ms-2'>{t('withAssignments')}</label>
        </div>
      )}
    </div>
  );
} 
