import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import ReactHelmet from '../ReactHelmet/ReactHelmet';
ReactHelmet.title = "Guardian Login | IG WAY";

export default function GuardianLogin() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [isloading, setIsloading] = useState(false);
    const [showpassword, setShowpassword] = useState(false);
    const [errors, setErrors] = useState();

    const validationSchema = Yup.object().shape({
        email: Yup.string().email(t('invalidEmail')).required(t('required')),
        password: Yup.string().required(t('required')),
        Remember: Yup.boolean(),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            Remember: false,
        },
        validationSchema: validationSchema,
        onSubmit: handleNext
    });

    async function handleNext(values) {
        console.log(values);
        setIsloading(true);
        try {
            let response = await axios.post(`https://back.igway.online/api/auth/parent/login`, values);
            console.log(response);
            // navigate('/nextPage'); // Adjust the route as necessary
        } catch (err) {
            setIsloading(false);
            if ( err.response.data.message) {
                setErrors(err.response.data.message);
            }
            else {
                setErrors(err.response.data.errors.message);
            }
            
        }
    }

    return (
        <div className="container-fluid p-0 m-0">
         <ReactHelmet title={`${t('login') } | IG WAY`} />
            <div className="row p-0 m-0">
                <div className="col-md-6 p-0 m-0">
                    <img src="/Images/parentlogin.png" className='w-100 vh-100 bg-side-color p-0 m-0 rounded-end-4 signupImg' alt="" />
                </div>
                <div className={`col-md-6 ${i18n.language === 'ar' ? 'pe-5' : ''}`}>
                <div className={`w-100 text-end ${i18n.language === 'ar' ? 'd-flex justify-content-end' : ''}`}>
                        <img src="/Images/reg-logo.png" className='mx-5 mt-2' alt="" />
                    </div>
                    <div className='row authenticationBody'>
                        <div className="col-md-10 offset-md-1 ">
                            <h1 className='ms-5 mb-2'>{t('guardian')}</h1>
                            <p className='ms-5 mb-3'>{t('guardianText')}<span onClick={() => navigate('/login')} className='text-warning pointer'>{t('here')}</span></p>


                            {errors && <div className="alert alert-danger col-md-10 offset-md-1">{errors}</div>}
                            <form className="row mt-4" onSubmit={formik.handleSubmit}>
                                <div className="col-md-10 offset-md-1 ps-0">
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">{t('Email')}</label>
                                        <input type="email" name='email' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} className="form-control" placeholder={t('Email')} aria-describedby="emailHelp" />
                                        {formik.touched.email && formik.errors.email ? <div className="alert alert-danger p-1">{formik.errors.email}</div> : null}
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="password" className="form-label">{t('Password')}</label>
                                        <div className="input-group">
                                            <input
                                                type={showpassword ? 'text' : 'password'}
                                                name="password"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.password}
                                                className="form-control pe-5"
                                                placeholder={t('Password')}
                                            />
                                            <button
                                                className="btn border-0 position-absolute end-0 top-50 translate-middle-y"
                                                type="button"
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    setShowpassword(!showpassword);
                                                }}
                                            >
                                                {showpassword ? <i className="fa-regular fa-eye"></i> : <i className="fa-solid fa-eye-slash"></i>}
                                            </button>
                                        </div>
                                        {formik.touched.password && formik.errors.password ? <div className="alert alert-danger p-1">{formik.errors.password}</div> : null}
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value={formik.values.Remember}
                                                onChange={formik.handleChange}
                                                name="Remember"
                                                id="Remember"
                                            />
                                            <label className="form-check-label" htmlFor="Remember">
                                                {t("remember")}
                                            </label>
                                        </div>
                                        <p onClick={() => navigate('/forgetpassword')} className='text-danger pointer'>{t('forgot')}</p>
                                    </div>
                                </div>

                                <div className={`row p-0 ${i18n.language === 'ar' ? 'justify-content-center' : ''}`}>
                                <div className="col-md-10 offset-md-1 pe-0">
                                        {isloading ? (
                                            <button type="button" className="btn btn-warning w-100 p-3 m-0">
                                                <i className="fas fa-spinner fa-spin"></i>
                                            </button>
                                        ) : (
                                            <button type="submit" disabled={!(formik.isValid && formik.dirty)} className="btn btn-warning w-100 p-3 m-0">
                                                {t('login')}
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </form>
                            <div className='w-100 text-center'>
                                <p className='mt-3'>{t('haveNoAccount')}<span onClick={() => navigate('/guardianregister')} className='pointer text-warning'>{t('signup')}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
