import React, { useEffect, useState } from "react";
import NotificationItem from "../NotificationItem/NotificationItem";
import axios from "axios";
import { Circles } from "react-loader-spinner";
import { useTranslation } from 'react-i18next';



export default function Notification() {
const { t } = useTranslation();
  const [notification, setNotification] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  

  const getNotification = async () => {
    try {
      const token = localStorage.getItem("token");
      let { data } = await axios.get(
        "https://back.igway.online/api/user/notifications/limit/7",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(data.data);
      setNotification(data.data);
        setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getNotification();
    // console.log(notification);
  }, []);
  return (
    <>
    { isLoading === true ?
      <div className="d-flex justify-content-center align-items-center spiner">
        <Circles
          height="80"
          width="80"
          color="#FFBC15"
          ariaLabel="circles-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
    </div>
      :
      <div className="container ms-3 notification">
        <div className="d-flex mb-3 align-items-center">
          <i className="fa-solid fa-arrow-left backIcon"></i>
          <h2 className="mx-2">{t("notification")}</h2>
        </div>
        <div className="p-3 me-3 bg-white border rounded-3">
            {notification.length === 0 ? <p className='text-center'>No Notifications</p> :
          (Object.entries(notification).map(([date, items], index) => {
            return <div>
                        <h6 className="mt-3">{date}</h6>
                        {items.map((item, index) => {
                            return <div className='d-flex align-items-center bg-light p-3 rounded-3 mb-3 border'>
                                        <img src={item.image.url} className=' notification-image rounded-circle me-3' alt="" />
                                        <div className='d-flex justify-content-center flex-column'>
                                            <p className='mb-0'>{item.body}</p>
                                            <p className='opacity-50 mb-0'>{item.formatted_created_at}</p>
                                        </div>
                                    </div> 
                                
                        })}
                    </div>
          }))}
        </div>
      </div>
    }
    </>
  );
}
