import React, { useState } from "react";
import {  Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
export default function Layout() {
  const {  i18n } = useTranslation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // close sidebar automatically when user navigates to a link 
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  }
  return (
    <>
      <div className={`container-fluid ${i18n.language === 'ar' ? 'rtl' : 'ltr'}`}>
          <div className="row">
          {i18n.language === 'en' ?  (
            <>
              <nav className="navbar navbar-expand-lg navbar-light col-md-10 offset-md-2 fixed-top background">
                <Navbar />
              </nav>

              <div className={`col-md-2 sidebar position-fixed bg-side vh-100 ${isSidebarOpen ? 'open' : ''}`}>
                  <Sidebar closeSidebar={closeSidebar} />
              </div>
              <div className="col-md-10 p-0 position-relative d-flex flex-column offset-md-2 mt-5 pt-5 background outlet-height">
              <i className="sidebar-toggle fa-solid fa-arrow-right" onClick={toggleSidebar}>
                        {isSidebarOpen ? 'X' : ''}
                      </i>
                <Outlet></Outlet>
              </div>
            </>

          ) : (
            <>
          <div className={`col-md-2 sidebarArabic position-fixed bg-side vh-100 ${isSidebarOpen ? 'open' : ''}`}>
              <Sidebar closeSidebar={closeSidebar} />
            </div>   
            
            <nav className="navbar arabicNav navbar-expand-lg navbar-light col-md-10 fixed-top background">
              <Navbar />
            </nav>
            <div className="d-flex justify-content-end p-0 responsive-arabic-outlet">
              <div className="col-md-10 arabicOutlet p-0 position-relative d-flex flex-column  mt-5 pt-5 background outlet-height">
              <i className="sidebar-toggle fa-solid fa-arrow-right" onClick={toggleSidebar}>
                        {isSidebarOpen ? 'X' : ''}
                      </i>
                <Outlet></Outlet>
              </div>
            </div>
            </>
          )}
          </div>
        </div> 
    </>
  );
}