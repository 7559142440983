import React from "react";
import { Link } from "react-router-dom";

export default function RecordingItem({ recording, index }) {
  
  return (
    <>
      <div className="col-12 col-sm-6 col-lg-4 p-2  mt-3" key={index}>
        <div className="ms-2">
            <Link to={`/student/recordingdetails/${recording.id}`}>
                <div className="bg-white shadow text-center rounded-3">
                    <img src={recording.image.url} className="w-100 home-course" alt="" />
                </div>
            </Link>
          <p className="mt-3 h6"> {recording.name} </p>
          <div className="d-flex justify-content-between align-items-center">
            <p className="m-0 opacity-50 h6 ">Ahmed Mahmoud</p>
            <p className="m-0 opacity-50 h6 ">3 hours ago</p> 
          </div>
        </div>
      </div>
    </>
  );
}
