import React, { useEffect, useState , useMemo} from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import CourseHomeItem from "../../Courses/CourseItem/CourseHomeItem";

export default function LandingPageHome() {
    const { t, i18n } = useTranslation();
    const [slidesToShow, setSlidesToShow] = useState(4);
    const [homeCourses, setHomeCourses] = useState([]);
    const settings = useMemo(() => ({
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow,
        slidesToScroll: 1,
      }), [slidesToShow]);
      const handle=() => {
        // window.scrollTo({
        //     top: document.documentElement.scrollHeight,
        //     behavior: 'smooth',
        //   });
      }
      useEffect(() => {
        const updateSlidesToShow = () => {
          if (window.innerWidth <= 572) { 
            setSlidesToShow(1);
          }
          else if (window.innerWidth <= 1150) {
            setSlidesToShow(2);
          }
          else {
            setSlidesToShow(4);
          }
        };
    
        window.addEventListener('resize', updateSlidesToShow);
        updateSlidesToShow(); 
    
        return () => {
          window.removeEventListener('resize', updateSlidesToShow);
        };
      }, []);
        
    useEffect(() => {
        getCourses();
    }, []);
    const getCourses = async () => {
        try {
            let { data } = await axios.get("https://back.igway.online/api/courses?scope=mini");
            setHomeCourses(data.data);
            console.log(data);
          } catch (err) {
            console.log(err);
          }
    }
    
    useEffect(() => {
        if (i18n.language === "ar") {
          document.body.classList.add("arabic");
        } else {
          document.body.classList.remove("arabic");
        }
      }, [i18n.language]);
    return (
        <>
        <div className=" bg-side-color pt-5">
            <div className="row p-0 m-0">
                <div className="landing-res col-md-6 d-flex justify-content-center flex-column align-items-center">
                    <div className={`d-flex flex-column align-start ${
                        i18n.language === "ar"
                        ? "landing-text-res-arabic"
                        : ""
                    }`} style={{paddingLeft:"25%"}}>
                        <p className="text-warning h1">{t("develop1") }</p>
                        <p className="text-warning h1">{t("develop2") }</p>
                        <p className="text-warning h1 mb-3">{t("develop3") }</p>                        
                        <h6 className="text-white" style={{width:"80%"}}>{t("developDiv") }</h6>
                    </div>
                    <div className=" d-flex w-100 justify-content-center align-items-center mt-3">
                            <Link className="btn btn-warning px-5 py-2 me-4" to={"/student/courses/offeredcourses"}>{t("enrollNow")}</Link>
                        <Link className="d-flex align-items-center btn py-2 me-4 border justify-content-center text-white" onClick={handle}>
                            <img src="/Images/call.png" className="hat " alt="Hat" />
                            <p className="m-0 mb-1 ms-2">{t("contact")}</p>
                        </Link>
                    </div>

                </div>
                <div className="col-md-6 d-flex justify-content-center position-relative">
                    <div className="landing-small-font position-absolute rounded-4 bg-white land-small-dev-stu text-center">
                        <div className="d-flex justify-content-center h-100 align-items-center flex-column">
                            <p className="m-0 ">{t("10k")}</p>
                            <p className="m-0 ">{t("studentEnrolled")}</p>
                        </div>
                    </div>
                    <div className="landing-small-font position-absolute rounded-4 bg-white land-small-dev-inst text-center">
                        <div className="d-flex justify-content-center h-100 align-items-center flex-column">
                            <p className="m-0 ">{t("300k")}</p>
                            <p className="m-0 ">{t("profInstructor")}</p>
                        </div>
                    </div>
                    <div className="landing-small-font position-absolute rounded-4 bg-white land-small-dev-course text-center">
                        <div className="d-flex justify-content-center h-100 align-items-center flex-column">
                            <img src="/Images/hat.png" className="hat img-none" alt="Stident Cap" />
                            <p className="m-0 ">{t("1K+")}</p>
                            <p className="m-0 ">{t("onlineCourses")}</p>
                        </div>
                    </div>
                    <img src="/Images/land-dev.png" className="w-50 land-img " alt="landing curve" />
                    
                </div>
            </div>
            
        </div>
        <div className="m-0 p-0 background">
            <div className="position-relative background">        
                <img src="/Images/line.png" className="w-100 m-0 p-0 position-absolute line1" alt="line" />
                <img src="/Images/line2.png" className="w-100 m-0 p-0 position-absolute line2" alt="line" />
            </div>

        </div>
        <div className="position-relative after-line ">
        </div>
        <div className="container-fluid mt-2 p-0">
            <div className="background">
        <div className="d-flex justify-content-center">
        <div className="row container">
                <div className="col-md-6 d-flex flex-column align-items-center divs-responsive">
                <div className="me-2 bg-side-color w-100 rounded-5 mt-1 p-3 d-flex align-items-center justify-content-center flex-wrap">
                    <img src="/Images/land3.png" className="land-dev-img img-fluid" alt="Experienced Instructors" />
                    <div className="d-flex flex-column ms-4 align-items-start">
                    <p className="text-warning mb-1">
                        {t("experiencedInst")}
                    </p>
                    <p className="text-white m-0">
                        {t("instDiv")}
                    </p>
                    </div>
                </div>
                <div className="me-2 border w-100 rounded-5 mt-4 p-3 d-flex align-items-center justify-content-center flex-wrap">
                    <img src="/Images/land4.png" className="land-dev-img img-fluid" alt="Payment Facilities & Installment Systems" />
                    <div className="d-flex flex-column ms-4 align-items-start">
                    <p className="text-warning mb-1">
                        {t("paymentSys")}
                    </p>
                    <p className="m-0">
                        {t("paymentDiv")}
                    </p>
                    </div>
                </div>
                <div className="me-2 border w-100 rounded-5 mt-4 p-3 d-flex align-items-center justify-content-center flex-wrap">
                    <img src="/Images/land5.png" className="land-dev-img img-fluid" alt="Academic Advisor" />
                    <div className="d-flex flex-column ms-4 align-items-start">
                    <p className="text-warning mb-1">
                        {t("academicAdvisor")}
                    </p>
                    <p className="m-0">
                        {t("academicDiv")}
                    </p>
                    </div>
                </div>
                </div>
                <div className="col-md-6 d-flex flex-column align-items-center">
                <div className="me-2 border w-100 rounded-5 mt-1 p-3 d-flex align-items-center justify-content-center flex-wrap">
                    <img src="/Images/land4.png" className="land-dev-img img-fluid" alt="Free Trial Sessions" />
                    <div className="d-flex flex-column ms-4 align-items-start">
                    <p className="text-warning mb-1">
                        {t("freeTrial")}
                    </p>
                    <p className="text-black m-0">
                        {t("trialDiv")}
                    </p>
                    </div>
                </div>
                <div className="me-2 border w-100 rounded-5 mt-4 p-3 d-flex align-items-center justify-content-center flex-wrap">
                    <img src="/Images/land5.png" className="land-dev-img img-fluid" alt="Live and Recorded Sessions" />
                    <div className="d-flex flex-column ms-4 align-items-start">
                    <p className="text-warning mb-1">
                        {t("recorded")}
                    </p>
                    <p className="text-black m-0">
                        {t("recordedDiv")}
                    </p>
                  </div>
                 </div>
                </div>
            </div>
        </div>
     
            </div>

        </div>      
        <div className="landing-page-home container-fluid background py-5">
            <div className="row">
                <div className="col-md-6 mt-3 d-flex justify-content-center align-items-center">
                    <div className="w-75 text-md-start text-center">
                        <h3 className="main-color">
                           {t("striveSuccess")}
                        </h3>
                        <p className="m-0">
                            {t("striveSuccessDiv")}
                        </p>
                        <div className="d-flex flex-column flex-md-row align-items-center justify-content-between mt-4">    
                            <div className="d-flex flex-column align-items-center student-responsive" style={{marginLeft: "20px"}}>
                                <p className="m-0 fw-bolder">{t("10,000")}</p>
                                <p>{t("studentEnrolled")}</p>
                            </div>
                        <div className="d-flex flex-column align-items-center ">
                            <p className="m-0 fw-bolder">{t("1,250")}</p>
                            <p>{t("courses")}</p>
                        </div>
                        <div className="d-flex flex-column align-items-center student-responsive" style={{marginRight: "20px"}}>
                            <p className="m-0 fw-bolder">{t("6")}</p>
                            <p>{t("Countries")}</p>
                        </div>
                    </div>
                    </div>
                </div>
           
            <div className="col-md-6 mt-3 d-flex  justify-content-start">
                <img src="/Images/students.png" className=" img-fluid " alt="Students" />
              </div>   
            </div>
            
        </div>
        <div className="background py-5">
            <div className="d-flex flex-column align-items-center justify-content-center w-50 mx-auto ">
                <h2 className="alltext-side-color">
                    {t("ourCourses")}
                </h2>
                <p className=" opacity-75 m-0">
                    {t("coursesDiv")}
                </p>
                <p className=" opacity-75 ">
                    {t("experience")}
                </p>
            </div>
        </div>
        <div className=" background container-fluid">
        {homeCourses.length > 0 ? (
            <div className="container px-4">
                <Slider {...settings}>
                  {homeCourses.map((course, index) => (
                    <CourseHomeItem course={course} key={index}/>
                  ))}
                </Slider>
            </div>
          ) : (
            <div className="col-md-12 px-4 background">
              <div className="row rounded-3 border py-4">
                <div className="col-md-6  text-center">
                  <img src="/Images/course-div.png" alt="Courses" />
                </div>
                <div className="col-md-6 p-3 px-4 d-flex flex-column justify-content-center align-items-center">
                  <h4>{t("startExploring") }</h4>
                  <h4>{t("learningJourney")}</h4>
                  <button className="btn btn-warning mt-4 px-2 py-2 w-25 fw-bold">
                    {t("startExloring") }
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="d-flex justify-content-center background">
            <img src="/Images/testimonial.png" className="w-50" alt="Testimonial" />
        </div>
        <div className="position-relative instru-dev">   
            <div className="flex-row background d-flex pb-5">
                <div className="col-md-6 mt-3 d-flex ps-5  certified-teacher" style={{justifyContent: 'end' , alignItems: 'center'}}>
                    <div className="w-75">
                        <h3 className="main-color">
                            {t("certified")}
                        </h3>
                        <h3 className="main-color">
                            {t("then")} <span className="alltext-side-color">{t("becomeInstructor")}</span>  
                        </h3>
                        <p className="m-0">
                            {t("joinTeam")}
                        </p>
                    </div>
                </div>
                <div className="col-md-6 mt-3 d-flex  justify-content-center">
                    <img src="/Images/instructor-img.png" className="w-50 img-none " alt="Instructor" />
                    
                </div>
            </div>
            <img src="/Images/line3.png" className=" img-none w-100 m-0 p-0 position-absolute line3" alt="line" />
            <img src="/Images/line4.png" className="img-none  w-100 m-0 p-0 position-absolute line3" alt="line" />
        
        </div>
        <div className=" after-line2">
        </div> 
        </>
    );
}
