import React from 'react';
import $ from 'jquery';

export default function LandingPageTeacherItem({ teacher, index }) {
  function handleMouseEnter(event) {
    $(event.currentTarget).closest(".text-center").find(".lend-teacher-desc-dev").fadeIn(1000);
  }

  function handleMouseLeave(event) {
    $(event.currentTarget).closest(".text-center").find(".lend-teacher-desc-dev").fadeOut(1000);
  }

  return (
    <>
      <div className="col-md-3 my-3" key={index}>
        <div
          className="text-center d-flex flex-column align-items-center position-relative"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <img src={teacher.user.avatar.url} alt="" className="w-75 teacher-land-img rounded-top-5" />
          <div className="bg-side-color w-75 rounded-bottom-5">
            <h6 className="text-white p-3 btn">Prof. {teacher.user.firstName}</h6>
          </div>
          <div className="rounded-top-5 rounded-bottom-5 position-absolute bottom-0 left-0 w-75 p-1 bg-side-color h-75 overflow-hidden fw-lighter lend-teacher-desc-dev" style={{ display: 'none' }}>
            <h6 className="text-white btn">Prof. {teacher.user.firstName}</h6>
            <p className="text-white text-start overflow-hidden lend-teacher-desc">
              {teacher.about.length > 250 ? teacher.about.substring(0, 250) : teacher.about}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
