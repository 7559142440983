import React, { useEffect, useState } from "react";
import $ from "jquery";
import RecordingItem from "../Courses/CourseItem/RecordingItem";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { Circles } from "react-loader-spinner";
import ReactHelmet from "../ReactHelmet/ReactHelmet";

export default function Recordings() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [recordings, setRecordings] = useState();
  const [courses, setCourses] = useState([]);


  const [selectedCourse, setSelectedCourses] = useState([]);
  const handleCheckboxChange = (setter, value) => {
    setter((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    );
  };

  const getCourses = async () => {
    try {
      const token = localStorage.getItem("token");
      let { data } = await axios.get("https://back.igway.online/api/user/mini-courses", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
        console.log(data);
      setCourses(data);
    } catch (err) {
      console.log(err);
    }
  };

  
  const getRecordings = async () => {
    setIsLoading(true);
    try {
        const token = localStorage.getItem("token");
        let { data } = await axios.get(
            `https://back.igway.online/api/user/recordings`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        setRecordings(data.data);
        setIsLoading(false);
    } catch (err) {
        console.log(err);
    }
  };

  
  useEffect(() => {
    getRecordings();
    getCourses();
  }, []);
  const handleSingleCheckboxChange = (selectedCourse) => {
    setSelectedCourses(selectedCourse);
  };
  function handleSlideDown(event) {
    $(event.currentTarget).parent().find(".drop-list").slideToggle();

    $(event.currentTarget).find(".arrow-icon").toggleClass("up");
  }
  const handleFilter = async (e) => {

    e.preventDefault();
    console.log(selectedCourse);
    setIsLoading(true);
    const token = localStorage.getItem("token");
    try {
      let {data} = await axios.get(`https://back.igway.online/api/user/course-recordings/${selectedCourse}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setRecordings([...data.data]);
      setIsLoading(false);
    } catch (error) {
    }
  };

  return (
    <>
      {isLoading === true ?
        <div className="d-flex justify-content-center align-items-center spiner">
          <Circles
            height="80"
            width="80"
            color="#FFBC15"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div> :
        <>
          <ReactHelmet title={`${t("recordings")} | IG WAY`} />
          <div className="container">

            <div className="row mt-3 ">
              <div className="col-md-3">
                <div className=" shadow p-3 rounded-2 bg-white mx-2">
                <div className="d-flex justify-content-between ">
              <div className="d-flex align-items-center">
                <img src="/Images/filter.png" className="   mx-2" alt="" />
                <h5>{t("filter")}</h5>
              </div>
              <button
                className="btn btn-link text-decoration-none text-warning"
                onClick={() => {
                  setIsLoading(true);
                  setSelectedCourses(null);
                  getRecordings();
                }}
              >
                {t("reset")}
              </button>
            </div>
            <hr className="w-75 mx-auto " />
            <form onSubmit={handleFilter}>
              <div className="mb-3">
                <label
                  onClick={handleSlideDown}
                  className="form-label btn border w-100 text-start d-flex justify-content-between align-items-center"
                >
                  <p className="p-0 m-0">Levels</p>
                  <img
                    src="/Images/arrow-down.png"
                    className="search arrow-icon"
                    alt=""
                  />
                </label>

                <div className="drop-list hidden px-3 bg-light py-2 rounded-2">
                  {courses.map((course, index) => (
                    <div key={index} className="form-check p-0 m-0 d-flex align-items-center justify-content-between mt-3">
                      <label className="form-check-label">{course.name}</label>
                      {/* <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        value={level.name}
                        onChange={() => handleCheckboxChange(setSelectedLevels, level.name)}
                        checked={selectedLevels.includes(level.name)}
                      /> */}
                      <input
                        className="form-check-input rounded-circle"
                        type="checkbox"
                        value={course.name}
                        onChange={() => handleSingleCheckboxChange(course.id)}
                        checked={selectedCourse === course.name}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="w-100 text-center">
                <button type="submit" className="btn border border-2 border-black w-50 mx-auto">
                  {t("apply")}
                </button>
              </div>
          </form>
                </div>
              </div>
              <div className="col-md-9">
                <div className="row me-2">
                  <div className="d-flex align-items-center justify-content-between ">
                    <div className="d-flex align-items-center bg-white search-div shadow rounded-2 py-1 px-2 mt-3">
                      <img src="/Images/search.png" className="search" alt="" />
                      <input
                        type="text"
                        className="form-control px-2 border-0 "
                        placeholder={t("search")}
                        />
                    </div>
                        
                  </div>
                  <div className="row"></div>
                  {recordings.length > 0 ?<> 
                    {recordings.map((recording,index) => {
                      return <RecordingItem recording={ recording}  key={index}/>
                    })} </>
                  : (
                    <div className="col-md-12 mt-2">
                      <div className="text-center">
                        <img src="/Images/Bookmarks-cuate.png" alt="" />
                        <h2 className="opacity-50">
                          {t("notSearched")}
                        </h2>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          
        </>
      }
    </>
  );
}
