import React ,{useState , useRef} from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";

export default function LandingPageFooter() {
    const { t, i18n } = useTranslation();
    const [successMessage, setSuccessMessage] = useState("");
    const [faildMessage, setFaildMessage] = useState("");
    const formRef = useRef(null);

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
          message: ""
        },
        validationSchema: Yup.object({
            name: Yup.string().required(t("enterFullname")),
            email: Yup.string().email(t("invalidEmail")).required(t("enterEmail")),
          message: Yup.string().required(t("writeQuestions"))
        }),
        onSubmit: async (values, { resetForm }) => {
            console.log(values);
            try {
              setSuccessMessage("");
              setFaildMessage("");
               await axios.post("https://back.igway.online/api/message/send", values);
              setSuccessMessage(t("message Sent Successfully"));
              
              resetForm();
            } catch (error) {
              setFaildMessage(t("message Sent Failed"));
          }
        }
      });

    return <>
        <div className="bg-side-color py-4 " id="footer">
          <div className="container">
            <div className="row">
                <div className="col-md-4 d-flex flex-column align-items-center text-center">
                    <img src="/Images/footer-logo.png" className="land-logo mb-3 " alt="Logo" />
                    <h4 className="text-white  ">IG WAY</h4>
                    <h4 className="text-white">{t("follow")}</h4>
                        <div className="d-flex justify-content-around mt-4">
                            <div className="rounded-circle bg-white footer-small-dev d-flex flex-column justify-content-center align-items-center me-2">
                                <i className="fa-brands fa-facebook-f"></i>
                            </div>
                            <div className="rounded-circle bg-white footer-small-dev d-flex flex-column justify-content-center align-items-center me-2">
                                <i className="fa-brands fa-instagram"></i>
                            </div>
                            <div className="rounded-circle bg-white footer-small-dev d-flex flex-column justify-content-center align-items-center me-2">
                                <i className="fa-brands fa-linkedin-in"></i>
                            </div>
                            <div className="rounded-circle bg-white footer-small-dev d-flex flex-column justify-content-center align-items-center">
                                <i className="fa-brands fa-youtube"></i>
                            </div>    
                        </div>
                        </div>
                        <div className="col-md-8 text-white">
                            <div className="questions-responsive">
                                <h3>{t("questions")}</h3>
                                <p className="opacity-75">{t("leaveQuestion") }</p>
                            </div>
                            <form ref={formRef} onSubmit={formik.handleSubmit}>
                            <div className="row">
                            {successMessage && (
                            <div className="col-12 mb-3">
                                <div className="alert alert-success" role="alert">
                                {successMessage}
                                </div>
                            </div>
                            )}
                                <div className="col-12 col-md-6 mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    placeholder={t("enterFullname")}
                                    {...formik.getFieldProps("name")}
                                />
                                {formik.touched.name && formik.errors.name ? (
                                    <div className="text-danger">{formik.errors.name}</div>
                                ) : null}
                                </div>
                                <div className="col-12 col-md-6 mb-3">
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    placeholder={t("enterEmail")}
                                    {...formik.getFieldProps("email")}
                                />
                                {formik.touched.email && formik.errors.email ? (
                                    <div className="text-danger">{formik.errors.email}</div>
                                ) : null}
                                </div>
                                <div className="col-12 mb-3">
                                <textarea
                                    className="form-control"
                                    id="message"
                                    placeholder={t("writeQuestions")}
                                    rows="3"
                                    {...formik.getFieldProps("message")}
                                ></textarea>
                                {formik.touched.message && formik.errors.message ? (
                                    <div className="text-danger">{formik.errors.message}</div>
                                ) : null}
                                </div>
                                <div className="w-100 d-flex justify-content-end">
                                <button
                                    type="submit"
                                    className="btn btn-warning mt-3 px-5"
                                    disabled={!formik.isValid || formik.isSubmitting}
                                >
                                    {t("submit")}
                                </button>
                                </div>
                            </div>
                            </form>
                        </div>
                </div>
          </div>
        </div>
        <div className=" bg-side-color d-flex justify-content-center align-items-center copywrite position-relative ">      
                <p className="text-white mt-3">{t("rights") }<span className="text-warning">IG WAY</span> </p>
        </div> 

</>


     }
