import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import axios from 'axios';


export default function ParentVerify() {
  const navigate = useNavigate();
  const inputRefs = useRef([]);
  const location = useLocation();
  const { email, id } = location.state;
  
  
  // Handle form submit
  const handleSubmit = async (values) => {
    const inputString = values.inputs.join(''); 
    let code = {"verification_code": inputString, "user_id": id};
    let { data } = await axios.post(`https://back.igway.online/api/auth/parent/check-verification-code`, code)
    .catch(err => {
      console.log(err);
    });
    console.log(data);
    if (data && data.token) { 
      localStorage.setItem('token', data.token);
    }
    
    // navigate('/educatorregister');
    
  };
  const formik = useFormik({
    initialValues: {
      inputs: Array(4).fill(''),
    },
    validationSchema: Yup.object({
      inputs: Yup.array().of(
        Yup.string().required('Required')
      ).min(4, 'Must fill all inputs').max(4, 'Must fill all inputs'),
    }),
    onSubmit: handleSubmit
  });


  // Handle input changes
  const handleInputChange = (e, index) => {
    const { value } = e.target;
    if (value.length === 1 && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
    const newValues = [...formik.values.inputs];
    newValues[index] = value;
    formik.setFieldValue('inputs', newValues);
  };

  // Handle key up for backspace
  const handleKeyUp = (e, index) => {
    if (e.key === 'Backspace' && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  // Check if all inputs are filled
  const allInputsFilled = formik.values.inputs.every(input => input.trim() !== '');

  return (
    <div className="container-fluid p-0 m-0">
      <div className="row p-0 m-0">
        <div className="col-md-6 p-0 m-0">
          <img src="/Images/reg-image4.png" className="w-100 vh-100 bg-side-color p-0 m-0 rounded-end-4 signupImg" alt="" />
        </div>
        <div className="col-md-6">
          <div className="w-100 text-end">
            <img src="/Images/reg-logo.png" className="mx-5 mt-2" alt="" />
          </div>
          <div className="row authenticationBody">
            <div className="col-md-10 offset-md-1">
              <h1 className="ms-5 mb-3">Sign up</h1>
              <form className="row mt-4" onSubmit={formik.handleSubmit}>
                <div className="col-md-10 offset-md-1 ps-0">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="w-100">
                        <div className="box d-flex ms-4 flex-column">
                          <img src="/Images/person-icon.png" alt="" />
                        </div>
                        <p className="mt-2 fw-lighter">Personal Info</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="w-100">
                        <div className="box d-flex ms-3 flex-column">
                          <img src="/Images/login-icon.png" alt="" />
                        </div>
                        <p className="mt-2 fw-lighter">Login Info</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="w-100">
                        <div className="box d-flex ms-3 flex-column">
                          <img src="/Images/verify.png" alt="" />
                        </div>
                        <p className="mt-2 fw-lighter">Verify</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="w-100 opacity-50">
                        <div className="box d-flex ms-3 flex-column last-box">
                          <img src="/Images/login-icon.png" alt="" />
                        </div>
                        <p className="mt-2 fw-lighter">Login Info</p>
                      </div>
                    </div>
                  </div>
                  <div className="verify-container position-relative">
                    <h1>Verify your password</h1>
                    <p>Please Enter The four digit code sent to</p>
                    <p className="text-danger">{email}</p>
                    <div className="mb-4">
                      <div className="row text-center">
                        {[...Array(4)].map((_, index) => (
                          <div className="col-md-3" key={index}>
                            <input
                              type="text"
                              className="border-bottom-custom text-center"
                              maxLength="1"
                              ref={(el) => (inputRefs.current[index] = el)}
                              onChange={(e) => handleInputChange(e, index)}
                              onKeyUp={(e) => handleKeyUp(e, index)}
                              value={formik.values.inputs[index]}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="line"></div>
                  </div>
                </div>
                <div className="row p-0 mt-4">
                  <div className="col-md-5 offset-md-1 pe-0">
                    <button type="button" onClick={() => navigate('/logininfo')} className="btn border border-black p-3 w-100">
                      Previous
                    </button>
                  </div>
                  <div className="col-md-5 pe-0">
                    <button type="submit" className="btn btn-warning w-100 p-3 m-0" disabled={!allInputsFilled}>
                      Next
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
