import React , { useState, useEffect } from "react";
import axios from "axios";
import Spinner from '../../Schedule/Spinner';
import { Circles } from "react-loader-spinner";
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Assignment() {
    const now = new Date();
    const startTime = new Date(now);
    const [assignment, setAssignment] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const { t } = useTranslation();

    const handleAnswerChange = (questionID, answer, questionIndex) => {
        const updatedAnswers = [...selectedAnswers];
        console.log(updatedAnswers);
      updatedAnswers[questionIndex] = {
          "question": questionID ,
          "studentAnswer": answer
    };
    setSelectedAnswers(updatedAnswers);
  };
    

    const { id } = useParams();
    
    const handleAssignmentSubmit = async () => {
        setSubmitLoading(true);
        try {
            const token = localStorage.getItem("token");
            let answers_data = { "answers": selectedAnswers };
            console.log(answers_data);
          let { data } = await axios.post(
            `https://back.igway.online/api/user/assignments/${assignment.id}/correct`,answers_data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
            console.log(data);
            setSubmitLoading(false);
            window.location.reload();
        } catch (err) {
          console.log(err);
        }
      };
    const handleAssignmentRetake = async () => {
        try {
            setSubmitLoading(true);
            const token = localStorage.getItem("token");
          let { data } = await axios.post(
            `https://back.igway.online/api/user/assignments/${assignment.id}/retack`,'',
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
            console.log(data);
            setSubmitLoading(false);
          window.location.reload();
        } catch (err) {
          console.log(err);
        }
      };
    const getAssignment = async () => {
        setIsLoading(true);
        try {
          const token = localStorage.getItem("token");
          let { data } = await axios.get(
            `https://back.igway.online/api/user/assignments/${id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log(data.data);
          const initialAnswers = data.data.questions.map((question) => ({
            question: question.id,
            studentAnswer: 0, // Initially, no answer is selected
          }));
          setSelectedAnswers(initialAnswers);
          setAssignment(data.data);
          setIsLoading(false);
        } catch (err) {
          console.log(err);
        }
      };
      useEffect(() => {
        getAssignment();
      }, []);
    
    // modal's assignment time out
      const handleTimeEnd = () => {
        setShowModal(true); 
      };
  // modal retake btn 
const handleRetake = async () => {
  try {
    setShowModal(false);
    
    setTimeout(async () => {
      await handleAssignmentRetake();
    }, 300); 
  } catch (error) {
    console.error("Error during retake:", error);
  }
};

// modal submit btn
const handleSubmit = async () => {
  try {
    setShowModal(false);
        setTimeout(async () => {
      await handleAssignmentSubmit();
    }, 300); 
  } catch (error) {
    console.error("Error during submit:", error);
  }
};

      useEffect(() => {
        console.log(selectedAnswers);
      }, [selectedAnswers]);

  startTime.setMinutes(startTime.getMinutes() + 1);
  return (
    <>
          {isLoading === true ?
              <div className="d-flex justify-content-center align-items-center spiner">
                  <Circles
                      height="80"
                      width="80"
                      color="#FFBC15"
                      ariaLabel="circles-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                  />
              </div> :
              <div className=" container">
        
                  <div className="row ms-2">
                      <div className="col-md-9">
                          <div className="d-flex align-items-center">
                              <i className="fa-solid fa-arrow-left backIcon"></i>
                              <h5 className="ms-4">Assignment</h5>
                          </div>
                          <h3>
                              Learn UI UX & Be a professional in this field
                          </h3>
                            {assignment.is_passed==true?
                            <div className="bg-color-unfill p-4 rounded-3 d-flex">
                                <div className="text-center w-100">
                                    <h2>Congratulations!</h2>
                                    <h2>You Passed this Assignment</h2>
                                    <h5>Your Grade: {assignment.result}</h5>
                                </div>
                            </div>:""}
                            {assignment.is_passed==false?
                            <div className="bg-danger bg-opacity-25 p-4 rounded-3 d-flex">
                                <div className="text-center w-100">
                                    <h2>Unfortunately!</h2>
                                    <h2>You Couldn’t Pass this Assignment</h2>
                                    <h5>Your Grade: {assignment.result}</h5>
                                </div>
                            </div>:""}
                          <div>
                          {assignment.questions.map((question, questionIndex) => (
  <div key={questionIndex}>
    <div className="d-flex justify-content-between my-2 mt-3">
      <p className="m-0">Question {questionIndex + 1} / {question.type}</p>
      <p className="m-0">({question.points} Point)</p>
    </div>
    
    <div className={`bg-color-unfill p-2 rounded-3 d-flex align-items-center mt-0 justify-content-between
        ${assignment.is_passed && question.is_answer_correct ? "bg-success bg-opacity-25" : ""} 
        ${assignment.is_passed!== null  && !question.is_answer_correct ? "bg-danger bg-opacity-25" : ""}`}>
      <p className={`m-0 `}>{question.question}</p>
      <p className="m-0">{question.student_answer===0?"You didn't answer this question":""}</p>
    </div>
    {question.answers.map((answer, answerIndex)  => (
        <div key={answerIndex} 
        className={`d-flex align-items-center mt-2 justify-content-between border p-2 rounded-3
      ${assignment.is_passed!==null &&
       !question.is_answer_correct  &&
        question.correct_answer.id===answer.id ? "bg-success bg-opacity-25" : ""}`}>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name={`question_${questionIndex}`}
            id={`question_${questionIndex}_answer_${answerIndex}`}
            value={answer.text}
            onChange={() => handleAnswerChange(question.id, answer.id,questionIndex)}
            
            {...(assignment.is_passed!==null && question.student_answer===answer.id ? { checked: true } : {})}
          />
          
                <label className={`form-check-label `}htmlFor={`question_${questionIndex}_answer_${answerIndex}`}>
            {answer.text}
          </label>
        </div>
        {/* <h1>{answer.id}</h1> */}
        </div>
        
    ))}
  </div>
))}
    <div className="d-flex justify-content-end">
        {assignment.is_passed==false || assignment.is_passed==true?(
            submitLoading ? <button type="button" className="btn btn-warning  mt-3"><i className="fas fa-spinner fa-spin"></i></button>
            :<button className="btn btn-warning  mt-3" onClick={handleAssignmentRetake}>Retake</button>
        )
        :(submitLoading ? <button type="button" className="btn btn-warning  mt-3"><i className="fas fa-spinner fa-spin"></i></button>
            :<button className="btn btn-warning  mt-3" onClick={handleAssignmentSubmit}>Submit</button>)}    
        </div>
      </div>
      </div>
        <div className="col-md-3  ">
          <div className="schedule me-3 p-3 rounded-4">
            <h6 className="alltext-black-color fw-bold pb-3 text-center">{assignment.is_passed!==null?"You Finished Your Exam":"Your Exam will End In"}</h6>
            <div className='d-flex justify-content-center spinner'>
              <Spinner startTime={assignment.is_passed !== null ? 0 : startTime} onTimeEnd={handleTimeEnd} />
            </div>
          </div>
        </div>
      </div>
    </div>
  }

      { showModal && (
        <div className=" modal fade show d-block igWallet-modal " role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered flex flex-column align-items-center justify-content-center" role="document">
            <div className="modal-content rounded-4">
            <div className="modal-header justify-content-end">
                  <button type="button" className="close btn btn-main-color text-white fw-bold px-1 py-0 rounded ms-2" aria-label="Close" onClick={() => setShowModal(false)}>
                    <span aria-hidden="true" className='p-1'>&times;</span>
                  </button>
                </div>
              <div className="modal-body">    
                <div className="d-flex flex-column align-items-center">
                  <div>
                  </div>
                  <h5 className="text-modal-res alltext-black-color text-center  my-3">Assignment times out</h5>
                  <img src="/Images/reg-image4.png" alt="Assignment" className="w-50"/>
                </div>
                <div className="d-flex justify-content-evenly my-4">
                <button className="btn-res-font btn btn-warning w-25 p-2 alltext-side-color fw-bold" onClick={handleRetake}>Retake</button>
                <button className="btn-res-font btn btn-warning w-25 p-2 alltext-side-color fw-bold" onClick={handleSubmit}>Show Results</button>
              </div>
              </div>
            </div>
             
            </div>
        </div>
      )}
    </>
  );
}
