import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import ReactHelmet from '../ReactHelmet/ReactHelmet';

export default function ForgetPasswordVerify() {
  const { t, i18n} = useTranslation();
  const navigate = useNavigate();
  const inputRefs = useRef([]);
  const location = useLocation();
  const { email, id } = location.state;
  const [isloading, setIsloading] = useState(false);
  const [errors, setErrors] = useState();

  
  
  // Handle form submit
  const handleSubmit = async (values) => {
    try {
      
      setIsloading(true);
      const inputString = values.inputs.join(''); 
      let code = {"verification_code": inputString, "user_id": id};
      let { data } = await axios.post(`https://back.igway.online/api/auth/password/otp-check`, code)
        
      
      if (data && data.token) { 
        localStorage.setItem('token_verify', data.token);
      }
      
      navigate('/newpassword');
      
    } catch (error) {
      
      setErrors(error.response.data.errors.message);
    }
    // console.log(data.data.token);
    
    
  };
  const formik = useFormik({
    initialValues: {
      inputs: Array(4).fill(''),
    },
    validationSchema: Yup.object({
      inputs: Yup.array().of(
        Yup.string().required(t("required"))
      ).min(4, t("fill")).max(4,  t("fill")),
    }),
    onSubmit: handleSubmit
  });


  // Handle input changes
  const handleInputChange = (e, index) => {
    const { value } = e.target;
    if (value.length === 1 && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
    const newValues = [...formik.values.inputs];
    newValues[index] = value;
    formik.setFieldValue('inputs', newValues);
  };

  // Handle key up for backspace
  const handleKeyUp = (e, index) => {
    if (e.key === 'Backspace' && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  // Check if all inputs are filled
  const allInputsFilled = formik.values.inputs.every(input => input.trim() !== '');

  return (
    <div className="container-fluid p-0 m-0">
      <ReactHelmet title={`${t('forgetPass') } | IG WAY`} />

      <div className="row p-0 m-0">
        <div className="col-md-6 p-0 m-0">
          <img src="/Images/reg-image4.png" className="w-100 vh-100 bg-side-color p-0 m-0 rounded-end-4 signupImg" alt="" />
        </div>
        <div className={`col-md-6 ${i18n.language === 'ar' ? 'pe-5' : ''}`}>
        <div className={`w-100 text-end ${i18n.language === 'ar' ? 'd-flex justify-content-end' : ''}`}>
            <img src="/Images/reg-logo.png" className="mx-5 mt-2" alt="" />
          </div>
          <div className="row authenticationBody">
            <div className="col-md-10 offset-md-1">
              
              <form className="row mt-4" onSubmit={formik.handleSubmit}>
                <div className="col-md-10 offset-md-1 ps-0">
                  <div className="verify-container position-relative">
                    <h1>{t('verifyPass')}</h1>
                    <p>{t('verifyText')}</p>
                    <p className="text-danger">{email}</p>
                {errors && <div className="alert alert-danger col-md-10 offset-md-1">{errors}</div>}
                    <div className="mb-4">
                      <div className="row text-center">
                        {[...Array(4)].map((_, index) => (
                          <div className="col-md-3" key={index}>
                            <input
                              type="text"
                              className="border-bottom-custom text-center"
                              maxLength="1"
                              ref={(el) => (inputRefs.current[index] = el)}
                              onChange={(e) => handleInputChange(e, index)}
                              onKeyUp={(e) => handleKeyUp(e, index)}
                              value={formik.values.inputs[index]}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="line"></div>
                  </div>
                </div>
                <div className={`row p-0 mt-4 ${i18n.language === 'ar' ? 'justify-content-center' : ''}`}>
                <div className="col-md-10 offset-md-1 pe-0">
                  {isloading ? <button type="button" className="btn btn-warning w-100 p-3 m-0"><i className="fas fa-spinner fa-spin"></i></button>
                    :<button type="submit" className="btn btn-warning w-100 p-3 m-0" disabled={!allInputsFilled}>
                    {t('verify')}
                  </button>}
                    
                    
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
