import React from 'react'
import { json, Navigate } from 'react-router-dom'

export default function LayoutRoute({ children }) {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user)
    {
        if (user.student.educationSystem === null) {
            
            return <Navigate to={"/educatorregister"}/>
        }
    }
    if (localStorage.getItem("token"))
    {
        return <Navigate to={"/student/home"}/>
    }
    else
    {
        return children;
    }

}
