import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import axios from "axios";
import { Circles } from "react-loader-spinner";
import Select from 'react-select';

export default function WalletRefundsRequest() {
  const [courses, setCourses] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedLessons, setSelectedLessons] = useState([]);
  const [reason, setReason] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [requestloading, setRequestloading] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  useEffect(() => {
    getCourses();
  }, []);
  const getCourses = async () => {
    try {
      const token = localStorage.getItem("token");
      let { data } = await axios.get("https://back.igway.online/api/user/mini-courses", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
        console.log(data);
      setCourses(data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    // Disable submit button if any field is empty
    setIsSubmitDisabled(
      !selectedCourse || selectedLessons.length === 0
    );
  }, [selectedCourse, selectedLessons, reason]);

  const handleCourseChange = async (e) => {
    const courseId = e.target.value;
    setSelectedCourse(courseId);

    // Fetch lessons based on selected course
    try {
      const token = localStorage.getItem("token");
      let { data } = await axios.get(`https://back.igway.online/api//user/lessons/course-lessons/${courseId}?scope=micro`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const lessonsData = data.map(lesson => ({
        value: lesson.id,
        label: lesson.name
      }));
      setLessons(lessonsData);
    } catch (err) {
      console.log(err);
    }
  };

  const handleLessonsChange = (selectedOptions) => {
    setSelectedLessons(selectedOptions.map(option => option.value));
  };

    const handleSubmit = async (e) => {
    setRequestloading(true);
    setError(null);
    setSuccess(null);
    e.preventDefault();
    try {
        const token = localStorage.getItem("token");
        const sendData={
            lessons_ids: selectedLessons,
            reason: reason
        }
        let result = await axios.post(`https://back.igway.online/api/user/transactions/refund/request`,sendData, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
        });
        setSuccess("Refund request sent successfully");
        setRequestloading(false);
    } catch (err) {
        setRequestloading(false);
        if (err.response.status !== 200) {
            setError(err.response.data.message);
        }
    }
    };

    return <>
        { isLoading === true ?
            <div className="d-flex justify-content-center align-items-center spiner">
                <Circles
                height="80"
                width="80"
                color="#FFBC15"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
          </div>
            :
    <div className="container">
      <div className="d-flex align-items-center">
        <i onClick={goBack} className="fa-solid fa-arrow-left backIcon"></i>
        <h5 className="ms-5 me-2">{t("Refund Request")}</h5>
      </div>
      <p className='mt-3'>Complete the following steps to get your money refund..</p>
      <div className="d-flex">
        <h6 className='me-3'>Note :</h6>
        <p>You can only request a refund before 48 hours from the lesson launching</p> 
      </div>
      <div className="row">
        <div className="col-md-9">
          <div className="bg-white p-3 rounded-4 border">
            {error && <p className="alert alert-danger">{error}</p>}
            {success && <p className="alert alert-success">{success}</p>}
            <form onSubmit={handleSubmit}>
              <div className="d-flex">    
                <div className="mb-3 w-50 me-2">
                  <label className="form-label text-th">{t("Course")}</label>
                  <select 
                    className="form-control" 
                    value={selectedCourse}
                    onChange={handleCourseChange}
                  >
                    <option value="">Select Course</option>
                    {courses.map(course => (
                      <option key={course.id} value={course.id}>
                        {course.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3 w-50">
                  <label className="form-label text-th">{t("Lesson")}</label>
                  <Select
                    options={lessons}
                    onChange={handleLessonsChange}
                    placeholder="Select Lessons"
                    isMulti
                    isDisabled={!selectedCourse}
                    closeMenuOnSelect={false}
                  />
                </div>
              </div>
              <div className="mb-3 w-100">
                <label className="form-label text-th">Reason</label>
                <textarea 
                  className="form-control" 
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  placeholder={t("Reason")} 
                  rows="4"
                ></textarea>
              </div>
              <div className="d-flex justify-content-center">
              {requestloading ? <button type="button" className="btn btn-warning w-50 mb-2 mt-3"><i className="fas fa-spinner fa-spin"></i></button>
                :
                <button 
                  type="submit" 
                  className="btn btn-warning py-2 w-50 text-th"
                  disabled={isSubmitDisabled}
                >
                  Submit
                </button>}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>}
    </>;
}
