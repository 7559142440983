import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Circles } from "react-loader-spinner";
import { useTranslation } from 'react-i18next';
import ReactHelmet from '../../ReactHelmet/ReactHelmet';

export default function RechargeTransactions() {
    const { t} = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [rechargeTransactions, setRechargeTransactions] = useState(null);
    const [page, setPage] = useState(1); 
    const [totalPages, setTotalPages] = useState(1);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");

    const getAttendanceTransactions = async (page) => {
        setIsLoading(true);
        
        try {
            const token = localStorage.getItem("token");
            let { data } = await axios.get(
                `https://back.igway.online/api/user/transactions/recharge?page=${page}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            console.log(data.data);
            setRechargeTransactions(data.data);
            setTotalPages(data.meta.last_page);
            setIsLoading(false);
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        getAttendanceTransactions();
        
    }, []);
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        try {
          const token = localStorage.getItem("token");
          let { data } = await axios.get(
            `https://back.igway.online/api/user/transactions/recharge?dateRange=${fromDate},${toDate}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setRechargeTransactions(data.data);
          setTotalPages(data.meta.last_page);
          setIsLoading(false);
        } catch (err) {
          console.log(err);
        }
      };
    const handleFromDateChange = (event) => {
        setFromDate(event.target.value);
      };
    
      const handleToDateChange = (event) => {
        setToDate(event.target.value);
    };
  const isFormComplete = fromDate && toDate;
    
    useEffect(() => {
        getAttendanceTransactions(page);
    }, [page]);
    return <>
     <ReactHelmet title={`${t('rechargeTransactions')} | IG WAY`} />

    {isLoading === true ?
    <div className="d-flex justify-content-center align-items-center spiner">
      <Circles
      height="80"
      width="80"
      color="#FFBC15"
      ariaLabel="circles-loading"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
      />
        </div> :
        <div className='px-2 mx-5 p-4'>
            <div className="bg-white p-3 rounded-3 shadow">
                <div className=''>
                    <div className="filter-container">
                    <div className="filter-header d-flex align-items-center">
                        <img src="/Images/filter.png" className='search me-2' alt="" />
                        <h5>{t("filter")}</h5>
                    </div>
                    <hr />
                    <form className='d-flex flex-column' onSubmit={handleSubmit}>
                        <div className="row w-100 mb-4">
                            <div className="col-md-3">        
                                <div className='d-flex flex-column'>    
                                    <label>{t("from")}</label>
                                    <input type="date" className='form-control'
                                    value={fromDate}
                                    onChange={handleFromDateChange}/>
                                    
                                </div>
                            </div>
                            <div className="col-md-3">        
                                <div className='d-flex flex-column'>    
                                    <label>{t("to")}</label>
                                    <input type="date"className='form-control'
                                    value={toDate}
                                    onChange={handleToDateChange} />
                                </div>
                            </div>
                            <div className="col-md-3 offset-md-3">        
                                <div className='d-flex flex-column'>    
                                    <button className=' btn btn-warning w-100 mt-2' 
                                    variant="primary" 
                                    type="submit"
                                    disabled={!isFormComplete}>{t("apply")}</button>
                                </div>
                            </div>
                        </div>
                        
                    </form>
                    </div>
                </div>
            </div>
            <h4 className='mt-4'>{t("transactionList")}</h4>
            <div className='bg-white shadow rounded-3 p-3  mb-3'> 
                <table className='table  table-bordered text-center '>
                    <thead>
                        <tr>
                            <th  className='bg-light'>{t("id")}</th>
                            <th  className='bg-light'>{t("date2")}</th>
                            <th  className='bg-light'>{t("balance")}</th>
                            <th  className='bg-light'>{t("currency")}</th>
                            <th  className='bg-light'>{t("status")}</th>
                        </tr>
                    </thead>
                    <tbody>
                    {rechargeTransactions.map((attendanceTransaction) => {    
                        return <tr>
                                <td>{attendanceTransaction.id}</td>
                                <td>{attendanceTransaction.formatted_date}</td>
                                <td>{attendanceTransaction.value}</td>
                                <td>{attendanceTransaction.currency} </td>
                                <td>{attendanceTransaction.status}</td>
                            </tr>
                        })}
                        
                    </tbody>
                </table>
            </div>
            <div className="pagination d-flex justify-content-center">
                    <button
                        className="btn btn-secondary me-2"
                        disabled={page === 1}
                        onClick={() => setPage(page - 1)}
                    >
                        {t('previous')}
                    </button>
                    <span>{t('page')} {page} {t('of')} {totalPages}</span>
                    <button
                        className="btn btn-secondary ms-2"
                        disabled={page === totalPages}
                        onClick={() => setPage(page + 1)}
                    >
                        {t('next')}
                    </button>
                </div>
        </div>
        }
</>
}
