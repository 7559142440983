import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Dropdown, DropdownButton } from "react-bootstrap";
import $ from "jquery";

export default function Sidebar({ closeSidebar }) {
  const { t } = useTranslation();
  function logoutDiv(e) {
    e.preventDefault();
    $("#transaction-div").slideToggle('slow');
  }

  return (
    <>
      <div className="logo-div-responsive d-flex justify-content-center align-items-center flex-column mt-3">
        <img src="/Images/nav-logo.png" className="logo-img-responsive w-50 mt-4" alt="" />
      </div>
      <div className="text-white ms-2">
        <ul className="mt-5 list-unstyled">
          <li className="mt-3" onClick={closeSidebar}>
            <Link to={"home"} className=" text-white text-decoration-none sidebar-text">
              {/* <i className="fa-solid fa-house mx-3 sidebar-icon"></i> */}
              <img
                src="/Images/home.png"
                className="mx-3 sidebar-icon"
                alt="home icon"
              />
              {t("home")}
            </Link>
          </li>
          <li className="mt-3" onClick={closeSidebar}>
            <Link
              to={"courses/offeredcourses"}
              className=" text-white text-decoration-none  sidebar-text"
            >
              <img
                src="/Images/category-icon.png"
                className="mx-3 sidebar-icon"
                alt="category icon"
              />
              {t("myCourses")}
            </Link>
          </li>
          <li className="mt-3" onClick={closeSidebar}>
            <Link to={"schedule"} className=" text-white text-decoration-none  sidebar-text">
              <img
                src="/Images/calendar-icon.png"
                className="mx-3 sidebar-icon"
                alt="calendar icon"
              />

              {t("mySchedule")}
            </Link>
          </li>
          <li className="mt-3" onClick={closeSidebar}>
            <Link
              to={"recordings"}
              className=" text-white text-decoration-none sidebar-text "
            >
              <img
                src="/Images/record-icon.png"
                className="mx-3 sidebar-icon"
                alt="record icon"
              />

              {t("recordings")}
            </Link>
          </li>
          <li className="mt-3">
            <Link
              className=" text-white text-decoration-none sidebar-text "
              id="transaction-btn"
              onClick={logoutDiv}
            >
              <img
                src="/Images/transaction-icon.png"
                className="mx-3 sidebar-icon"
                alt="transaction icon"
              />
              {t("transactions")}
            </Link>
          </li>
        </ul>
        <div id="transaction-div" className="">
          <ul className=" list-unstyled transaction-child">
            <li className=" transaction-child">
              <Link  onClick={closeSidebar}
                className=" text-white text-decoration-none ms-4 transaction-child-res
transaction-child-res
transaction-child-res transaction-child  sidebar-text"
                to="attendance-transactions"
              >
                {t("attendanceTransactions")}
              </Link>
            </li>
            <li className="mt-3 transaction-child">
              <Link  onClick={closeSidebar}
                className=" text-white text-decoration-none ms-4 transaction-child-res
transaction-child-res
transaction-child-res transaction-child sidebar-text "
                to="recharge-transactions"
              >
                {t("rechargeTransactions")}
              </Link>
            </li>
            <li className="mt-3 transaction-child">
              <Link onClick={closeSidebar}
                className=" text-white text-decoration-none ms-4 transaction-child-res
transaction-child-res
transaction-child-res transaction-child sidebar-text "
                to="wallet-refunds"
              >
                {t("walletRefunds")}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
